import { useProject } from "../../hooks/useProject";
import {  useEffect, useRef, useState } from "react";
import List from "../base/list"
import { useLoadingContext } from "../../context/loadingLayout";
import { useSearchBarContext } from "../../context/search";
import DialogConfirm from "../common/dialogConfirm";
import ListItem from "../common/listItem"
import { useHttpContext, useHttpUtil } from "context/httpContext";

type ProjectDetail = {
    projectId: string,
    name: string,
    createTime: string,
    image: string,
}

const PAGE_SIZE = 20 ;
export default function ProjectList(){
    const [gotoAdd, gotoEditor] = useProject();
    const loadingContext = useLoadingContext();
    const searchBarContext = useSearchBarContext();
    const httpUtil = useHttpUtil();

    const inputRefs = useRef<HTMLInputElement[]>([]);

    const [list, setList] = useState<ProjectDetail[]>();
    const [isDelete, setIsDelete] = useState<boolean>(false);
    const [projectId, setProjectId] = useState<string>();
    const [editItem, setEditItem] = useState<ProjectDetail>();
    const [currentPage, setCurrentPage] = useState<number>(1);
    
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const listElement = useRef<HTMLDivElement>(null);

    
    useEffect(() => {
        currentPage > 0 && getList()
    }, [currentPage])

    useEffect(() => {
        if(searchBarContext?.keyword !== null) currentPage == 1 ? getList() : setCurrentPage(1);
    }, [searchBarContext?.keyword])

    // useEffect(() => {
    //     getList()
    // }, [currentPage])

    // useEffect(() => {
    //     currentPage == 1 ? getList() : setCurrentPage(1);
    // }, [searchBarContext?.keyword])

    
    const getList = async () => {
        if(currentPage < 1 || isLoading) return ;

        setIsLoading(true);
        const data = await httpUtil.post('/biz/project/list', {
            currentPage,
            pageSize: PAGE_SIZE,
            keyword: searchBarContext?.keyword || ''
        })
        setList(data.data);
        if(loadingContext?.isLoadingLayout) loadingContext?.setIsLoadingLayout(false);

        let isContinueLoad = true ;
        if(!data.data || currentPage == 1 && data.data.length == 0) {
            setCurrentPage(0);
            setList([]);
            isContinueLoad = false ;
        }else{
            if(currentPage == 1){
                setList(data.data)
            }else{
                setList(list?.concat(data.data))
            }
            if(data.data.length < PAGE_SIZE){
                setCurrentPage(0);
                isContinueLoad = false ;
            }
        } 
        setIsLoading(false);
        if(isContinueLoad){
            setTimeout(() => {
                scrollContent();
            },500)
        }
    }

    const scrollContent = async function () {
        if(listElement.current && currentPage > 0 && !isLoading){
            const { scrollTop, scrollHeight, clientHeight} = listElement.current ;
            if(scrollTop + clientHeight + 20 > scrollHeight){
                setCurrentPage(currentPage + 1)
            }
        }
    }

    const toDel = (projectId: string) => {
        setProjectId(projectId);
        setIsDelete(true);
    }

    const del = async () => {
        const data = await httpUtil.post('/biz/project/delete', {
            projectId
        })
        if(!data.errCode){
            currentPage == 1 ? getList() : setCurrentPage(1);
        }
        setIsDelete(false);
    }

    const updateName = async (index: number, name: string) => {
        if(!list) return ;
        const item = list[index];
        await httpUtil.post('/biz/project/update/name', {
            projectId : item.projectId,
            name
        })
        setEditItem(undefined)
        setList(list.map((item: ProjectDetail, i) => i == index ? {...item, name, isEdit: false} : item ));
    }

    return (
        <div className="h-full overflow-y-auto" ref={listElement} style={{
            ...(listElement.current ? {  height : window.innerHeight - listElement.current.getBoundingClientRect().top } : {})
        }} onScroll={scrollContent}>
            <List tabs={{ title : 'Project'}}>
                <ListItem isActive={true} className="aspect-[3/4] h-[265px]" onClick={() => gotoAdd()}>
                    <div className="flex grow flex-col w-full px-[10px] items-center justify-center text-center">
                        <img src="/icon/icon-create-new.png" className="w-[80px]" />
                        <p className="mt-20px w-full text-[16px] text-[#14A5FA] font-bold ">Create New Project</p>
                    </div>
                </ListItem>
                {
                    list && list.map((item: ProjectDetail,index) => (
                        <ListItem key={index} className="aspect-[3/4] h-[265px]" onClick={() => gotoEditor(item.projectId)}>
                            <div className="flex grow w-full items-center justify-center rounded-t-[10px] bg-[#EFF3F5]">
                                <img src={item.image || '/no-image.png'} className="flex-none max-w-[100%] max-h-[100%] rounded-t-[10px]" />
                            </div>
                            <div className="h-[60px] w-full px-[10px] flex flex-col justify-center border-t" onClick={(e) => {e.stopPropagation();}}>
                                {
                                    editItem && editItem.projectId == item.projectId ?
                                    <input value={editItem.name} className="border border-[#14A5FA] rounded-[5px] py-[3px] px-[5px] outline-0"
                                        ref={(el: HTMLInputElement) => inputRefs.current[index] = el}
                                        onKeyDown={(e: any) => { e.key === 'Enter' && updateName(index, editItem.name)}}
                                        onChange={(e: any) => { setEditItem({...editItem, name:e.target.value})}} onBlur={(e: any) => updateName(index, e.target.value)}/>
                                    :
                                    <p className="font-bold text-[14px] truncate" title={item.name}>{item.name}</p>
                                }
                                
                                <span className="text-[#BFBFBF] truncate">{item.createTime}</span>
                            </div>
                            <div className="absolute right-0 top-0 cursor-pointer group/item group-hover/list:block hidden"
                                onClick={(e) => {e.stopPropagation();}}>
                                <span className="block py-[5px] px-[10px] text-center">...</span>
                                <div className="absolute top-[25px] flex flex-col w-[120px] border rounded bg-[#FFFFFF] z-[10] group-hover/item:block hidden">
                                    <div className="flex items-center gap-[10px] py-[12px] pl-[15px] hover:bg-[#F8F8F8]"
                                        onClick={() => {setEditItem({...item}); setTimeout(() => inputRefs.current[index].select(), 10) }}>
                                        <img src="/icon/icon-edit.png" className="w-[18px]" />
                                        <span>Rename</span>
                                    </div>
                                    <div className="flex items-center gap-[10px] py-[12px] pl-[15px] hover:bg-[#F8F8F8]"
                                        onClick={(e) => {e.stopPropagation(); gotoAdd(item.projectId)}}>
                                        <img src="/icon/icon-copy.png" className="w-[18px]" />
                                        <span>Duplicate</span>
                                    </div>
                                    <div className="flex items-center gap-[10px] py-[12px] pl-[15px] hover:bg-[#F8F8F8]"
                                        onClick={(e) => {e.stopPropagation(); toDel(item.projectId)}}>
                                        <img src="/icon/icon-delete.png" className="w-[18px]" />
                                        <span>Delete</span>
                                    </div>
                                </div>
                            </div>
                        </ListItem>
                    ))
                }
                
            </List>
            {
                isDelete &&
                <DialogConfirm cancel={() => setIsDelete(false)} confirm={() => del()} />
            }
        </div>
    )
}