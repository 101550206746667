import FunctionButton from "../../../components/common/funcButton";
import Score from "../../../components/common/score";
import { useProject } from "../../../hooks/useProject";
import { useParams } from "react-router";
import React, {  useEffect, useRef, useState } from "react";
import EditorMeunBar from "./menubar";
import { usePermissionContext } from "../../../context/permission";
import MockupEditor,{ Action, ActionName, EventCallbackParams, EventCallbackParamsType, FineTuningObject, ItemBase64, MockupObject } from "./mockupEditor";
import { useHttpContext, useHttpUtil } from "context/httpContext";
import FineTuning from "./fineTuning";
import Button from "components/common/button";
import { useGuideContext } from "context/guide";
import Slider from "components/common/slider";
import Toolskit from "./toolskit";
import Tools from "libs/utils/tools";
import SmartColor from "./smartColor";

type DownloadParameter = {
    isAllowDownload: boolean,
    isShow : boolean,
    model ?: string,
    width ?: number,
    height ?: number
}

export default function ProjectEditorDetail(){
    const params = useParams() ;

    const [gotoAdd] = useProject();
    const updatorContext = usePermissionContext();
    const httpUtil = useHttpUtil();
    const httpContent = useHttpContext();
    const guideContext = useGuideContext();

    const [projectId, setProjectId] = useState<string>()

    const [project, setProject] = useState<ProjectInfo>(() => {
        return {
            name : ''
        }
    });

    const [editorAction, setEditAction] = useState<Action>();

    // 编辑器的手势模式0-2
    const [modeType, setModeType] = useState<number>(0);


    // 编辑器选择图片时的工具栏模式1-4
    const [imageEditType, setImageEditType] = useState<number>(0);
    // 编辑器选择图片时的工具栏模式1-3
    const [modelEditType, setModelEditType] = useState<number>(0);

    // 画笔类型
    const [penEditType, setPenEditType] = useState<number>(0);
    // 画笔模式(涂抹-1 / 擦除-2)
    const [penEditModel, setPenEditModel] = useState<number>(0);
    const [penSize, setPenSize] = useState<number>(5);
    // 是否正在确认画笔区域(AI消除-向量图 与 区域选择-底图)
    const [isConfirmPen, setIsConfirmPen] = useState<boolean>(false);

    // 选中图像数据, type- 2：向量图 1: 底图
    const [checkedElementData, setCheckedElementData] = useState<MockupObject>();

    // 当前正在操作的对象,其他功能按钮将禁用(当前有:Mockup, AI消除进行中, 去除背景, 智能换色)
    const [ currentOperateElement, setCurrentOperateElement ] = useState<OperateElement>();
    

    // 下载参数
    const [downloadParameter, setDownloadParameter] = useState<DownloadParameter>({
        isAllowDownload: false,
        isShow : false,
        width: 0,
        height: 0
    });

    // 是否需要保存
    const [isSaveChange, setIsSaveChange] = useState<boolean>(false);
    // 正在保存...
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const [toolskitData, setToolskitData] = useState<EventCallbackParams>();
    const [smartColorCallbackData, setSmartColorCallbackData] = useState<EventCallbackParams>();


    const inputNameRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setProjectId(params.id as string)
    }, [params.id])

    useEffect(() => {
        if(projectId){
            loadProject();
        }
    },[projectId]);

    const beforeUploadEventHandler = (event: any) => {
        const confirmationMessage = 'Are you sure you want to leave this page? Unsaved changes will be lost.';
        if(isSaveChange){
            event.returnValue = confirmationMessage; 
            return confirmationMessage; // 兼容
        }  
    }
    useEffect(() => {
        window.addEventListener('beforeunload', beforeUploadEventHandler);
        return () => {
            window.removeEventListener('beforeunload', beforeUploadEventHandler);
        }
    },[isSaveChange])


    const loadProject = async function(){
        const data = await httpUtil.post('/biz/project/detail', {
            projectId
        })
        if(!data.errCode){
            setProject(data.data);
            // loadingContext?.setIsLoadingLayout(false);
        }
    }

    const saveProjectName = async function(){
        await httpUtil.post('/biz/project/update/name', {
            projectId,
            name : project.name
        })
    }

    // 编辑器手势模式
    const selectedMode = (type : number) => {
        // setModeType(type);

        setEditAction({
            name: ActionName.CHANGE_MOUSE_MODE,
            params : `${type}`
        })
    }

    
    // useEffect(() => {
    //     if(checkedElementData?.type == 1 && penEditType == 2){
    //         document.body.addEventListener('mouseup', mockupAiAnalysis)
    //     }
    //     return () => {
    //         document.body.removeEventListener('mouseup', mockupAiAnalysis);
    //     }

    // },[checkedElementData?.type, penEditType])

    // 开始智能分析
    // const mockupAiAnalysis = (event: MouseEvent) => {
        // setEditAction({
        //     name: ActionName.MOCKUP_AI_ANALYSIS
        // })
    // }

    useEffect(() => {
        if(checkedElementData?.type == 2){ // 向量图的画笔
            if(penEditType == 1){
                setEditAction({
                    name: ActionName.VECTOR_AI_ERASE_MAN_START
                })
            }else if(penEditType == 2){
                setEditAction({
                    name: ActionName.VECTOR_AI_ERASE_CIRCLE_START
                })
            }
        }else if(checkedElementData?.type == 1){ // 底图的画笔
            if(penEditType == 1){
                setEditAction({
                    name: ActionName.MOCKUP_AREA_START
                })
                setPenEditModel(1);
            }else if(penEditType == 2){
                setEditAction({
                    name: ActionName.MOCKUP_AI_AREA_START
                })
                setPenEditModel(1);
            }
        }
    },[penEditType])

    // 底图切换模式，涂抹|擦除
    const switchMockupEditModel = (type: number) => {
        setEditAction({
            name: ActionName.MOCKUP_PEN_SWITCH,
            params : type == 1 ? '1' : '0'
        })
        setPenEditModel(type);
    }

    // 设置画笔大小
    const penChangeHandler = (size: number) => {
        setEditAction({
            name: ActionName.SETTING_PEN_SIZE,
            params: `${size}`
        })
    }

    // 确认画笔
    const confirmPen = () => {
        if(isConfirmPen) return ;
        setIsConfirmPen(true);
        const name = checkedElementData?.type == 2 ? ActionName.VECTOR_AI_ERASE_CLEAR : penEditType == 1 ? ActionName.MOCKUP_CONFIRM : ActionName.MOCKUP_AI_CONFIRM

        setEditAction({
            name
        })
    }

    // 取消画笔
    const cancelPen = () => {
        if(penEditType == 0) return ;
        setEditAction({
            name: ActionName.VECTOR_AI_ERASE_CLOSE,
        })
        setPenEditType(0);
        setPenEditModel(0);
        setImageEditType(0);
        setModelEditType(0);
    }

    // 点击素材库的图片，需加载到编辑器中
    const choiceMaterialImage = (url: string) => {
        cancelPen();
        setTimeout(() => {
            setEditAction({
                name: ActionName.LOADING_IMAGE,
                params: url
            })
        },30);
    }

    // 颜色选择器，colors是在 选中模型状态下，使用了画笔功能后出现的，会自动识别画笔区域的所有颜色值
    // const [colors, setColors] = useState<string[]>(['#FFFFFF', '#FFE27A' , '#FFDFA1', '#000000']);
    // const onSelectedColor = (color: string , index: number) => {
    //     const tempColors = [...colors];
    //     tempColors[index] = color ;
    //     setColors(tempColors)
    // }

    // 下载
    const download = () => {
        setEditAction({
            name: ActionName.DOWNLOAD,
            params: project.name
        })
    }

    // 选中编辑器图片时的功能模式
    const selectedImageEditMode = async (type : number) => {
        if(type == 1){ // 转模型
            // 设置
            checkedElementData && setCurrentOperateElement({
                type: ActionName.MOCKUP,
                element: checkedElementData,
                status: OperateStatus.PROCESSING
            })
            const resData = await httpUtil.post('/biz/func/mockup',{
                projectId: projectId
            })
            if(!resData.errCode){
                setEditAction({
                    name: ActionName.MOCKUP,
                    params: `${checkedElementData?.uuid}`
                })

                updatorContext?.updator();
            }else{
                setCurrentOperateElement(undefined);
            }
            return ;
        }

        if(type == 3){ // 去除背景
            if(checkedElementData && checkedElementData.mImageUrl){
                // 设置
                checkedElementData && setCurrentOperateElement({
                    type: ActionName.VECTOR_REMOVE_BACKGROUD,
                    element: checkedElementData,
                    status: OperateStatus.PROCESSING
                })

                setImageEditType(type);
                setTimeout(() => {
                    setEditAction({
                        name: ActionName.VECTOR_REMOVE_BACKGROUD,
                    })
                }, 20);
            }
            return ;
        }
        if(type == 4){} // 获取画笔选中区域的颜色数组
        if(type == 5){} // 转高清

        type = imageEditType == type ? 0 : type ;
        if(imageEditType == 2 && type == 0){ // 画笔取消
            cancelPen();
        }
        setImageEditType(type);
    }

    // 选中编辑器模型时的功能模式
    const selectedModelEditMode = (type : number) => {
        if(type == 2){ // 栅格化，转图片
            setEditAction({
                name: ActionName.VECTOR
            })
            return ;
        }
        type = modelEditType == type ? 0 : type ;
        if(modelEditType == 1 && type == 0){ // 画笔取消
            cancelPen();
        }
        setModelEditType(type);
    }

    useEffect(() => {
        setImageEditType(0);
        setModelEditType(0)
        cancelPen();
    },[checkedElementData?.type])



    // 编辑器的相关回调监听
    const onEditorDataListener = async (data: EventCallbackParams) => {
        // console.info('onEditorDataListener', data)
        if(data.type >= 200 && data.type <= 210){ // 图层相关
            setToolskitData(data);
            return ;
        }
        if(data.type >= 300 && data.type <= 310){ // 智能换色相关
            setSmartColorCallbackData(data);
            return ;
        }
        switch(data.type){
            case EventCallbackParamsType.ITEM_BASE64_CHANGE:
                // const itemBase64 = data.data as ItemBase64 ;
                // console.info(itemBase64);
                if(data?.data && projectId){
                    await httpUtil.uploadBytes({
                        projectId,
                        id: data.data.md5 + data.data.type,
                        bytes: data?.data.base64
                    })
                }
                break;
            case EventCallbackParamsType.MOUSE_CHANGE_CALLBACK:
                setModeType(+data.data)
                break;
            case EventCallbackParamsType.OBJECT_SELECT: // 对象选中
                setDownloadParameter({...downloadParameter, width: (data.data as MockupObject)?.mImageWidth, height: (data.data as MockupObject)?.mImageHeight, isAllowDownload: true})

                // data.data && setTimeout(() => {
                    data.data && setCheckedElementData({...(data.data as MockupObject)})
                // }, 150);
                setToolskitData(data); // 通知工具面板
                break;
            case EventCallbackParamsType.OBJECT_CANCEL_SELECT: // 取消选中
                setDownloadParameter({...downloadParameter, isAllowDownload: false, isShow: false})
                setCheckedElementData(undefined)

                setToolskitData(data); // 通知工具面板
                break;
            case EventCallbackParamsType.MOCKUP_COVERTING: // Mockup正在执行中
                const uuid = data.data ;

                break;
            case EventCallbackParamsType.ACTION_FINISH: // Action执行完成
                setCurrentOperateElement(undefined);
                break;
            case EventCallbackParamsType.EDITOR_CHANGE: // 编辑器有数据变化
                setIsSaveChange(true);
                setToolskitData(data); // 通知工具面板，会带有是否有撤销/恢复状态
                break;
            case EventCallbackParamsType.GET_EDITOR_DATA_CALLBACK: // 获取编辑器数据后的回调
                const resultData = data.data ;
                const resData = await httpUtil.uploadProject({
                    projectId : projectId as string,
                    content : resultData.data,
                    bytes: resultData.previewImage || null
                })
                setIsSaving(false);
                if(!resData.errCode){
                    setIsSaveChange(false);
                    httpContent?.response(0, 'Saved successfully')
                }else{
                    httpContent?.response(100, 'Save failed')
                }
                break;
            case EventCallbackParamsType.DOWNLOAD_CALLBACK: // 下载之后的回调
                const res = await httpUtil.post('/biz/images/build', {
                    fileType: downloadParameter.model || 'PNG',
                    width: downloadParameter.width,
                    height: downloadParameter.height,
                    projectId,
                    imageBase64 : data.data
                })
                if(!res.errCode){
                    httpUtil.download(Tools.completeUrl(res.data), project.name);
                }
                setDownloadParameter({...downloadParameter, isShow: false})
                break;
            case EventCallbackParamsType.VECTOR_REMOVE_BACKGROUD_CALLBACK: // 去除背景之后的回调
                const rgbResData = await httpUtil.post('/biz/func/remove/background',{
                    srcImage: data.data,
                    projectId
                })

                setCurrentOperateElement(undefined);

                if(!rgbResData.errCode){
                    setEditAction({
                        name: ActionName.REPLACE_IMAGE,
                        params : rgbResData.data
                    })
                }
                break;
            case EventCallbackParamsType.GET_PEN_SIZE_CALLBACK: // 获取画笔大小的回调
                data.data && setPenSize(+data.data)

                // 智能换色的画笔大小通知
                setSmartColorCallbackData(data);
                break;
            case EventCallbackParamsType.PEN_CONFIRM_CALLBACK: //  画笔确认。 AI消除成功后的回调(向量图) | 确认区域画笔成功后的回调(底图)
                cancelPen();
                setIsConfirmPen(false);
                break;
        }
    }

    const saveProjectData = () => {
        setIsSaving(true);
        // 加一点延迟，在加载画布数据时，会造成浏览器卡导致保存加载进度未显示
        setTimeout(() => {
            setEditAction({ name: ActionName.GET_EDITOR_DATA});
        },5)
    }

    const dragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = "copy"
    }

    const dragEnd = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const url = event.dataTransfer.getData('imageUrl') ;
        url && choiceMaterialImage(url);
    }

    // 用来做智能换色的功能按钮的定位，后续改成context来实现
    const [isShowLeftBar, setIsShowLeftBar] = useState<boolean>(false);
    const showLeftBar = (flag: boolean) => {
        setIsShowLeftBar(flag);
    }

    return (
        <div className="min-h-screen flex flex-col ">
            <header className="flex items-center h-[60px] px-[30px] border-b-[#EBEBEB] border-b-[1px]">
                <div className="flex items-center gap-[13px]">
                    <img src="/logo-short.png" className="w-[30px] h-[30px] cursor-pointer opacity-[0.8]"
                        onClick={() => {location.href = ('/')}}/>
                    <div className="relative pl-[10px] group">
                        <img src="/icon/icon-editor-more.png" className="w-[25px] cursor-pointer"/>
                        <div className="absolute z-[10002] top-[25px] left-[5px] flex flex-col w-[150px] bg-[#FFFFFF] rounded-[10px] shadow-xl text-center group-hover:flex hidden">
                            <span className="py-[8px] cursor-pointer hover:bg-[#D9F2FF] rounded-t-[10px]"
                                onClick={() => inputNameRef.current?.select()}>Rename title</span>
                            <span className="py-[8px] cursor-pointer hover:bg-[#D9F2FF]"
                                onClick={() => gotoAdd()}>New project</span>
                            <span className="py-[8px] cursor-pointer hover:bg-[#D9F2FF]"
                                onClick={() => gotoAdd(projectId)}>Duplicate project</span>
                            <span className="py-[8px] cursor-pointer hover:bg-[#D9F2FF] rounded-b-[10px]" 
                                onClick={() => location.href = ('/')}>My project</span>
                        </div>
                    </div>
                    <div className="">
                        <input type="text" value={project?.name} className="py-[7px] px-[10px] border focus:border-[#14A5FA] w-[140px] outline-none rounded-[6px] focus:select-all"
                            ref={inputNameRef}
                            onChange={(e) => project && setProject({...project, name:e.target.value})} 
                            onBlur={(e) => saveProjectName()}/>
                    </div>
                    <div className="flex gap-[10px]">
                        <FunctionButton isActive={modeType == 0} iconPath="/icon/icon-editor-selector.png" onClick={() => selectedMode(0)} 
                            bubble={{ title: 'Move'}} className="hover:bg-[#D9F2FF]"/>
                        <FunctionButton isActive={modeType == 1} iconPath="/icon/icon-editor-hand.png" onClick={() => selectedMode(1)} 
                            bubble={{ title: 'Hand'}} className="hover:bg-[#D9F2FF]"/>
                        {/* <FunctionButton isActive={modeType == 2} iconPath="/icon/icon-editor-frame.png" onClick={() => selectedMode(2)} 
                            bubble={{ title: 'Frame', desc: 'Multiple images can be put together into a single whole.'}}/> */}
                    </div>
                </div>
                <div className="grow flex justify-center">
                {
                    (checkedElementData?.type == 2 || guideContext?.isStartGuide) && 
                        <div className="flex gap-[8px]" id="step2_element">
                            <FunctionButton id="step3_element" isActive={false} 
                                isLoading={currentOperateElement?.type == ActionName.MOCKUP && currentOperateElement?.status == OperateStatus.PROCESSING} 
                                isDisabled={currentOperateElement && currentOperateElement.type != ActionName.MOCKUP}
                                iconPath="/icon/icon-editor-mockup.png" onClick={() => selectedImageEditMode(1)} 
                                bubble={{ title: 'Mockup', desc: 'Use the selected layer as a mockup image. Drag it to the mockup base and it will stretch to fit the surface.'}} />
                            <FunctionButton isActive={imageEditType == 2} iconPath="/icon/icon-editor-pen.png" onClick={() => selectedImageEditMode(2)}
                                isDisabled={currentOperateElement !== undefined}
                                bubble={{ title: 'Intelligent elimination', desc: 'Remove unwanted elements.'}} >
                                {
                                    imageEditType == 2 && penEditType != 2 && 
                                    <div className="absolute top-[47px] left-[-65px] flex flex-col w-[210px] border rounded-[5px] bg-[#FFFFFF] z-[1201] text-[14px] font-bold text-[#000000] shadow-[0_4px_4px_0px_rgba(0,0,0,0.25)]">
                                        {
                                            penEditType == 0 ?
                                            <>
                                                <div className="flex items-center gap-[10px] py-[8px] pl-[15px] hover:bg-[#D9F2FF]"
                                                    onClick={(e) => {setPenEditType(1); e.stopPropagation();}}>
                                                    <img src="/icon/icon-editor-image-pen-daub.png" className="w-[23px]" />
                                                    <span className="">Daub</span>
                                                </div>
                                                <div className="flex items-center gap-[10px] py-[8px] pl-[15px] hover:bg-[#D9F2FF]"
                                                    onClick={(e) => {setPenEditType(2); e.stopPropagation();}}>
                                                    <img src="/icon/icon-editor-image-pen-circle.png" className="w-[23px]" />
                                                    <span>Circle Select</span>
                                                </div>
                                            </>
                                            :
                                            penEditType == 1 && 
                                            <div className="flex items-center gap-[10px] py-[4px] px-[15px] hover:bg-[#D9F2FF]"
                                                onClick={(e) => e.stopPropagation()}>
                                                <span>Brush size</span>
                                                <Slider init={penSize} changeHandler={penChangeHandler} />
                                            </div>
                                        }

                                        {
                                            // penEditType == 1 && 
                                            // <div className="absolute w-[210px] left-[230px] top-[0px] bg-[#FFFFFF] px-[12px] py-[9px] flex items-center gap-[10px] rounded-[5px] shadow-[0_4px_4px_0px_rgba(0,0,0,0.25)]"
                                            //     onClick={(e) => e.stopPropagation()}>
                                            //     <span>Brush size</span>
                                            //     <Slider init={penSize} changeHandler={penChangeHandler} />
                                            // </div>
                                        }
                                    </div>
                                }
                            </FunctionButton>
                            <FunctionButton isActive={false} 
                                isLoading={currentOperateElement?.type == ActionName.VECTOR_REMOVE_BACKGROUD && currentOperateElement?.status == OperateStatus.PROCESSING} 
                                isDisabled={currentOperateElement && currentOperateElement.type != ActionName.VECTOR_REMOVE_BACKGROUD}
                                iconPath="/icon/icon-editor-nobg.png" onClick={() => selectedImageEditMode(3)} 
                                bubble={{ title: 'Remove background', desc: 'One click removal of background.'}} />
                            {/* <FunctionButton isActive={imageEditType == 4}  iconPath="/icon/icon-editor-model-color.png" onClick={() => selectedImageEditMode(4)} 
                                bubble={{ title: 'Smart color change', desc: 'Switch product model colors.'}} /> */}
                            {/* <FunctionButton isActive={imageEditType == 5} iconPath="/icon/icon-editor-hd.png" onClick={() => selectedImageEditMode(5)} 
                                bubble={{ title: 'Improve clarity', desc: 'Increases image resolution, making it sharper and cleaner.'}} /> */}
                        </div>
                }
                {
                    checkedElementData?.type == 1 && 
                        <div className="flex gap-[8px]">
                            <FunctionButton isActive={modelEditType == 1} iconPath="/icon/icon-editor-model-pen.png" onClick={() => {selectedModelEditMode(1); setPenEditType(0)}}
                                bubble={{ title: 'Area selection', desc: 'Use Brush draw or Smart Click to select areas where patterns can be placed on the mockup.'}} >
                                {
                                    modelEditType == 1 && penEditType != 2 && 
                                    <div className="absolute top-[47px] left-[-45px] flex flex-col w-[210px] border rounded-[5px] bg-[#FFFFFF] z-[1201] text-[14px] font-bold text-[#000000] shadow-[0_4px_4px_0px_rgba(0,0,0,0.25)]">
                                        {
                                            penEditType == 0 ?
                                            <>
                                                <div className="flex items-center gap-[10px] py-[8px] pl-[15px] hover:bg-[#D9F2FF]"
                                                    onClick={(e) => {setPenEditType(1); e.stopPropagation();}}>
                                                    <img src="/icon/icon-editor-image-pen-daub.png" className="w-[23px]" />
                                                    <span className="">Daub</span>
                                                </div>
                                                <div className="flex items-center gap-[10px] py-[8px] pl-[15px] hover:bg-[#D9F2FF]"
                                                    onClick={(e) => {setPenEditType(2); e.stopPropagation();}}>
                                                    <img src="/icon/icon-editor-pen-smart-click.png" className="w-[23px]" />
                                                    <span>Smart click</span>
                                                </div>
                                            </>
                                            :
                                            <div className="flex items-center gap-[10px] py-[4px] px-[15px]"
                                                onClick={(e) => e.stopPropagation()}>
                                                <div className="p-[3px] rounded-[5px]"
                                                    style={{
                                                        ...(penEditModel === 1 ? { backgroundColor: '#D9F2FF'} : {})
                                                    }}
                                                    onClick={() => switchMockupEditModel(1)}><img src="/icon/icon-mockup-pen-1.png" className="w-[25px] h-[25px]"/></div>
                                                {
                                                    penEditType === 1 && 
                                                    <div className="p-[3px] rounded-[5px]"
                                                        style={{
                                                            ...(penEditModel === 2 ? { backgroundColor: '#D9F2FF'} : {})
                                                        }}
                                                        onClick={() => switchMockupEditModel(2)}><img src="/icon/icon-mockup-pen-2.png" className="w-[25px] h-[25px]"/></div>
                                                }
                                                <Slider init={penSize} changeHandler={penChangeHandler} />
                                            </div>
                                        }
                                    </div>
                                }
                            </FunctionButton>
                            <FunctionButton isActive={false} iconPath={"/icon/icon-editor-model-frame.png"} onClick={() => selectedModelEditMode(2)} 
                                bubble={{ title: 'Rasterizing', desc: 'Convert the mockup with all its attached layers into a raster image.'}} />
                        </div>
                }
                </div>
                
                <Score>
                    {
                        // isSaveChange &&
                        <Button txt="Save" loadingTxt="Saving" disabled={!isSaveChange} rounded={10} clickEvent={saveProjectData} isLoading={isSaving} />
                    }
                    {
                        (downloadParameter && downloadParameter.isAllowDownload || guideContext?.isStartGuide) &&
                        <div id="step4_element" className="relative flex items-center rounded-[10px] bg-[#14A5FA] text-[#FFFFFF] px-[20px] gap-[5px] cursor-pointer"
                            onClick={() => { downloadParameter && setDownloadParameter({...downloadParameter, isShow: !downloadParameter?.isShow})}}>
                            <img src="/icon/icon-editor-download.png" className="w-[21px]" />
                            <span className="text-[14px]">Download</span>

                            {
                                downloadParameter.isShow && 
                                <div className="absolute flex flex-col w-[260px] p-[15px] bg-[#FFFFFF] right-0 top-[48px] z-[1002] text-[#000000] text-[14px] gap-[15px] shadow-xl rounded-[10px]"
                                    onClick={(e) => e.stopPropagation()}>
                                    <p className="text-[16px] font-bold">Download Options</p>
                                    <span className="">File Type</span>
                                    <select className="border rounded-[5px] py-[11px] pl-[15px] pr-[9px] outline-none" value={downloadParameter.model}
                                        onChange={(e) => {setDownloadParameter({...downloadParameter, model: e.target.value})}}>
                                        <option value="PNG">PNG</option>
                                        <option value="JPG">JPG</option>
                                    </select>
                                    <div className="flex items-center gap-[10px]">
                                        <span>Width:</span><input type="text" disabled value={downloadParameter.width + ''} className="outline-none text-[12px] border rounded-[5px] p-[10px] w-[58px] text-center "
                                            onChange={(e) => {setDownloadParameter({...downloadParameter, width: +e.target.value})}}/>
                                        <span>Height:</span><input type="text" disabled value={downloadParameter.height + ''} className="outline-none text-[12px] border rounded-[5px] p-[10px] w-[58px] text-center"
                                            onChange={(e) => {setDownloadParameter({...downloadParameter, height: +e.target.value})}}/>
                                    </div>
                                    <button className="w-full py-[11px] rounded-[10px] bg-[#14A5FA] text-[#FFFFFF] cursor-pointer"
                                        onClick={download}>Download</button>
                                </div>
                            }
                        </div>
                    }
                    
                </Score>
            </header>
            <div className="relative grow flex h-full">
                <EditorMeunBar onImageClick={choiceMaterialImage} switchShowCallback={showLeftBar}/>
                <div className="relative flex grow bg-[#EFF3F5]"
                    onDragOver={(event) => dragOver(event)}
                    onDrop={(event) => dragEnd(event)}
                    style={{
                        cursor: modeType == 0 ? 'default' : 'pointer'
                    }}>
                    <MockupEditor action={editorAction} project={project} onDataListener={onEditorDataListener}/>

                </div>
                {
                    imageEditType == 4 &&
                    // <ColorSelector colors={colors} onSelectedColor={onSelectedColor}/>
                    <SmartColor editorCallbackData={smartColorCallbackData} triggerEditorAction={(action: Action) => setEditAction(action)} 
                        isQuickCalcBar={isShowLeftBar} finishCallback={() => selectedImageEditMode(4)}/>
                }
                {
                    checkedElementData?.type == 2 && 
                    <FineTuning data={checkedElementData} triggerEditorAction={(action: Action) => setEditAction(action)} />
                }
                
                <Toolskit data={toolskitData} triggerEditorAction={(action: Action) => setEditAction(action)}/>
                
                {
                    (checkedElementData?.type == 2 && imageEditType == 2 || 
                        checkedElementData?.type == 1 && modelEditType == 1) && penEditType > 0 &&
                    <div className="absolute bottom-[20px] left-[50%] translate-x-[100px] flex items-center gap-[10px] z-[1202]">
                        <div className="bg-[#D9D9D9] p-[8px] rounded-[10px] cursor-pointer"
                            onClick={cancelPen}><img src="/icon/icon-cancel.png" className="w-[34px] h-[34px]"/></div>
                        <div className="bg-[#14A5FA] p-[8px] rounded-[10px] cursor-pointer "
                            onClick={confirmPen}>
                            {
                                isConfirmPen ?
                                <p className="animate-spin">
                                    <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4287" width="34" height="34"><path d="M96 512c0-19.33 15.67-35 35-35s35 15.67 35 35c0 191.09 154.91 346 346 346s346-154.91 346-346-154.91-346-346-346c-19.33 0-35-15.67-35-35s15.67-35 35-35c229.75 0 416 186.25 416 416S741.75 928 512 928 96 741.75 96 512z" fill="#10F28D" p-id="4288"></path></svg>
                                </p>
                                :
                                <img src="/icon/icon-confirm.png" className="w-[34px] h-[34px]"/>
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export type ProjectInfo = {
    projectId?: string,
    name : string,
    content?: string,
    baseUrl?: string,
    urls?: any
}

enum OperateStatus {
    START,
    PROCESSING,
    FINISH
}

type OperateElement = {
    type : ActionName,
    element : MockupObject,
    status : OperateStatus
}