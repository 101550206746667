

export default function DialogConfirm(props : { width ?: number, title ?: string, content ?: string[], cancel ?: Function, confirm : Function}){
    const { width = 320, title = 'Sure you want to delete it?', content = [], cancel, confirm } = props ;
    return (
        <div className="fixed w-full h-full left-0 top-0 bg-[#000000]/[0.5] z-[100003]">
            <div className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] p-[20px] bg-[#ffffff] rounded-[15px]"
                style={{
                    width : `${width}px`
                }}>
                <img src="/icon/icon-close.png" className="absolute right-[15px] top-[10px] w-[20px] cursor-pointer" 
                    onClick={() => {cancel ? cancel() : confirm()}}/>
                <p className="font-bold text-[16px]">{title}</p>
                {
                    content.length > 0 && 
                        <div className="mt-[10px]">
                            {
                                content.map((str: string, index: number) => (
                                    <p className="mt-[10px] text-[14px]" key={index}>{str}</p>
                                ))
                            }
                        </div>
                }
                <div className="text-right mt-[30px]">
                    {
                        cancel ?
                            <button className="rounded-[10px] bg-[#EBEBEB] text-[#000000] px-[16px] py-[6px]"
                            onClick={() => cancel()}>No</button>
                        : null
                    }
                    <button className="rounded-[10px] bg-[#14A5FA] text-[#FFFFFF] px-[16px] py-[6px] ml-[10px]"
                        onClick={() => confirm()}>Yes</button>
                </div>
            </div>
        </div>
    )
}