import Bubble from "./bubble";

export default function FunctionButton(props : {
        isActive ?: boolean, isLoading ?: boolean, isDisabled ?: boolean,
        iconPath : string, id?: string, className?: string,
        onClick : Function,
        children ?: any, 
        bubble ?: {
            title: string, desc?: string
        }}){
    const { children, onClick, iconPath, isActive = false, isLoading = false, isDisabled = false, id = '', className= '', bubble} = props ;

    return (
        <div className={`relative p-[5px] rounded-[3px] cursor-pointer ${className} ${isActive ? 'bg-[#D9F2FF]' : ''}`} id={id}
            onClick={() => !isDisabled && !isLoading && onClick()}>
            <div className="relative group ">
                {
                    isLoading ?
                    <p className="animate-spin">
                        <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4287" width="25" height="25"><path d="M96 512c0-19.33 15.67-35 35-35s35 15.67 35 35c0 191.09 154.91 346 346 346s346-154.91 346-346-154.91-346-346-346c-19.33 0-35-15.67-35-35s15.67-35 35-35c229.75 0 416 186.25 416 416S741.75 928 512 928 96 741.75 96 512z" fill="#10F28D" p-id="4288"></path></svg>
                    </p>
                    :
                    <img src={!isDisabled ? iconPath : iconPath.replace('.','-disabled.')} className="w-[25px]" />
                }
                {                
                    bubble && <Bubble {...bubble} className='group-hover:block'/>
                }
            </div>
            {children}
        </div>
    )
}