import { useEffect, useState } from 'react';
import { Action, ActionName, FineTuningObject } from './mockupEditor';


/**
 * 手动微调，主要包括，饱和度;值的范围-1-1, 不透明度;值的范围0-1, 对比度:值的范围-1- 1, 亮度:值的范围-1-1
 * @param props 属性
 * @param callback 修改属性后的回调
 * @returns 
 */
export default function FineTuning(props: {data : FineTuningObject, triggerEditorAction: (action: Action) => void}){
    const {data, triggerEditorAction} = props ;

    const [fineTuning, setFineTuning] = useState<FineTuningObject>(() => {
        return {
            opacity: data.opacity, // 透明度
            saturation: data.saturation * -1, // 饱和度
            brightness: data.brightness, // 亮度
            contrast:data.contrast  // 对比度
        }
    });

    useEffect(() => {
        if(data.mapUUId){ // 向量图在底图之上
            // 如果传递进来的是编辑器里的预设值(预设值不能放在编辑器库里面，它是对所有向量图生效，现在固定此种写法)
            // 改成业务所需预设值，透明度: 0.85  饱和度: -0.1
            if(data.opacity == 1 && data.saturation == 0 && data.contrast == 0 && data.brightness == 0){
                change({
                    opacity: 0.85, // 透明度
                    saturation: -0.1, // 饱和度
                    brightness: 0, // 亮度
                    contrast: 0  // 对比度
                })
            }
        }else{ // 向量图不在底图之上
            // 如果向量图不在底图上，但前面因为在底图上待过，会被预设成业务所需值，如果从底图上出来了，则恢复
            if(data.opacity != 1 || data.saturation != 0 || data.contrast != 0 || data.brightness != 0){
                change({
                    opacity: 1, // 透明度
                    saturation: 0, // 饱和度
                    brightness: 0, // 亮度
                    contrast: 0  // 对比度
                })
            }
        }
        
    }, [ data ])

    const change = (changeData: FineTuningObject) => {
        setFineTuning(changeData);
        triggerEditorAction({
            name : ActionName.VECTOR_ON_MOCKUP_SETTING,
            params: changeData
        })
    }

    const calcValue = (value: number, step: number, min : number = -1, max : number = 1) => {
        if(step != 0) value = parseFloat((value + step).toFixed(2)) ;
        return Math.max(min, Math.min(max, value))
    }

    const calc = (key: string, step: number) => {
        if(key === 'opacity') return change({...fineTuning, opacity: calcValue(fineTuning.opacity, step, 0)});
        if(key === 'saturation') return change({...fineTuning, saturation: calcValue(fineTuning.saturation, step)});
        if(key === 'brightness') return change({...fineTuning, brightness: calcValue(fineTuning.brightness, step)});
        if(key === 'contrast') return change({...fineTuning, contrast: calcValue(fineTuning.contrast, step)});
    }

    return (
        <>
            {
                data.mapUUId && <div className="absolute right-0 top-0 bg-[#FFFFFF] w-[240px] h-full p-[20px]">
                    <p className="text-[16px] font-bold">Manual fine-tuning</p>
                    <div className="flex flex-wrap mt-[15px] gap-[10px]">
                        <div className="w-full flex items-center">
                            <p className="text-[14px] basis-1/2 font-semibold">Transparency</p>
                            {/* <div className="relative w-[100%] h-[3px] rounded-[5px] bg-[#D9D9D9] mt-[30px] cursor-pointer">
                                <div className="absolute left-[20%] top-[-3px] border border-[#000000] rounded-[50%] w-[10px] h-[10px] bg-[#FFFFFF]"></div>
                                <div className="absolute left-[20%] ml-[-7px] top-[-26px] bg-[#D9D9D9] rounded-[10px] px-[4px]">0.6</div>
                            </div> */}
                            <div className="relative text-right basis-1/2">
                                <img src="/icon/icon-sub.png" className="absolute w-[20px] h-[20px] right-[58px] top-[3px] cursor-pointer" 
                                    onClick={() => calc('opacity', -0.05)}/>
                                <input type="number" className="text-center px-[20px] py-[3px] border border-[#D9D9D9] rounded-[5px] w-[80px]"
                                    step={0.05} max={1} min={0} value={fineTuning.opacity} 
                                    onChange={(e) => change({...fineTuning, opacity: calcValue(+e.target.value, 0, 0)})}  />
                                <img src="/icon/icon-plus.png" className="absolute w-[20px] h-[20px] right-[2px] top-[3px] cursor-pointer" 
                                    onClick={() => calc('opacity', 0.05)}/>
                            </div>
                        </div>
                        <div className="w-full mt-[20px] flex">
                            <p className="text-[14px] basis-1/2 font-semibold flex items-center">Saturation</p>
                            <div className="relative text-right basis-1/2">
                                <img src="/icon/icon-sub.png" className="absolute w-[20px] h-[20px] right-[58px] top-[3px] cursor-pointer" 
                                    onClick={() => calc('saturation', -0.05)}/>
                                <input type="number" className="text-center px-[20px] py-[3px] border border-[#D9D9D9] rounded-[5px] w-[80px]"
                                    step={0.05} max={1} min={-1} value={fineTuning.saturation} 
                                    onChange={(e) => change({...fineTuning, saturation: calcValue(+e.target.value, 0, -1)})}  />
                                <img src="/icon/icon-plus.png" className="absolute w-[20px] h-[20px] right-[2px] top-[3px] cursor-pointer" 
                                    onClick={() => calc('saturation', 0.05)}/>
                            </div>
                        </div>
                        <div className="w-full mt-[20px] flex">
                            <p className="text-[14px] basis-1/2 font-semibold flex items-center">Contrast</p>
                            <div className="relative text-right basis-1/2">
                                <img src="/icon/icon-sub.png" className="absolute w-[20px] h-[20px] right-[58px] top-[3px] cursor-pointer" 
                                    onClick={() => calc('contrast', -0.05)}/>
                                <input type="number" className="text-center px-[20px] py-[3px] border border-[#D9D9D9] rounded-[5px] w-[80px]"
                                    step={0.05} max={1} min={-1} value={fineTuning.contrast} 
                                    onChange={(e) => change({...fineTuning, contrast: calcValue(+e.target.value, 0, -1)})}  />
                                <img src="/icon/icon-plus.png" className="absolute w-[20px] h-[20px] right-[2px] top-[3px] cursor-pointer" 
                                    onClick={() => calc('contrast', 0.05)}/>
                            </div>
                        </div>
                        <div className="w-full mt-[20px] flex">
                            <p className="text-[14px] basis-1/2 font-semibold flex items-center">Luminance</p>
                            <div className="relative text-right basis-1/2">
                                <img src="/icon/icon-sub.png" className="absolute w-[20px] h-[20px] right-[58px] top-[3px] cursor-pointer" 
                                    onClick={() => calc('brightness', -0.05)}/>
                                <input type="number" className="text-center px-[20px] py-[3px] border border-[#D9D9D9] rounded-[5px] w-[80px]"
                                    step={0.05} max={1} min={-1} value={fineTuning.brightness} 
                                    onChange={(e) => change({...fineTuning, brightness: calcValue(+e.target.value, 0, -1)})}  />
                                <img src="/icon/icon-plus.png" className="absolute w-[20px] h-[20px] right-[2px] top-[3px] cursor-pointer" 
                                    onClick={() => calc('brightness', 0.05)}/>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}