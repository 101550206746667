import { createContext, useContext, useState } from "react";


interface PermissionContextObj {
    statsNumber: number,
    updator: Function,
    isShowUpgrade: boolean,
    upgrade: Function
  }
  export const PermissionContext = createContext<PermissionContextObj|null>(null);
  
  export function usePermissionContext() : PermissionContextObj | null{
    return useContext(PermissionContext);
  }
  
  /**
   * 权限
   */
  export default function Permission(props: {children: any}) {
      const { children} = props ;
  
      const [statsNumber, setStatsNumber] = useState<number>(0);
      const [isShowUpgrade, setIsShowUpgrade] = useState<boolean>(false);

      const updator = () => {
        setStatsNumber(statsNumber + 1);
      }

      const upgrade = () => {
        setIsShowUpgrade(!isShowUpgrade);
      }
  
      return (
          <>
            <PermissionContext.Provider value={{
              statsNumber, updator, isShowUpgrade, upgrade
            }}>
              {children}
            </PermissionContext.Provider>
          </>
      );
  
  }