
export default function Button(props: { txt: string, loadingTxt?: string, rounded?: number, clickEvent: Function, isLoading?: boolean, disabled ?: boolean}){
    const {txt, loadingTxt = 'Processing', rounded = 5, clickEvent, isLoading = false, disabled = false} = props ;
    return (
        <button className="text-[14px] px-[13px] py-[6px] flex items-center gap-[10px]"
            style={{
                borderRadius: rounded + 'px',
                backgroundColor: isLoading || disabled ? '#EBEBEB' : '#14A5FA',
                color: isLoading || disabled ? '#9f9f9f' : '#FFFFFF'
            }}
            disabled={disabled}
            onClick={() => {!isLoading && clickEvent()}} >
            {
                isLoading && 
                    <div className="animate-spin w-[25px] h-[25px]">
                        <svg fill={ isLoading ? "#9f9f9f" : "#FFFFFF"} viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4287" width="25" height="25"><path d="M96 512c0-19.33 15.67-35 35-35s35 15.67 35 35c0 191.09 154.91 346 346 346s346-154.91 346-346-154.91-346-346-346c-19.33 0-35-15.67-35-35s15.67-35 35-35c229.75 0 416 186.25 416 416S741.75 928 512 928 96 741.75 96 512z" p-id="4288"></path></svg>
                    </div>
            }
            <span>{isLoading ? loadingTxt : txt}</span>
        </button>
    )
}