import { createContext, useContext, useEffect, useState } from "react";
import { Action, ActionName, EventCallbackParams, EventCallbackParamsType, MockupObject } from "./mockupEditor";

type ToolskitContextObj = {
    triggerEditorAction: Function,
    triggerUpdateList: Function,
    currentLayerUuid: number
}

export const ToolskitContext = createContext<ToolskitContextObj|null>(null);

function useToolskitContext() : ToolskitContextObj | null{
    return useContext(ToolskitContext)
}

export default function Toolskit(props: {data: EventCallbackParams | undefined, triggerEditorAction: (action: Action) => void}){
    // const {panelScale = '100'} = props ;
    const {data, triggerEditorAction} = props ;

    const [isShowLayout, setIsShowLayout] = useState<boolean>(false);
    const [ panelScale, setPanelScale] = useState<number>(100);
    const [ layerList, setLayerList] = useState<LayerItem[]>();
    const [ currentLayerUuid, setCurrentLayerUuid] = useState<number>(0);

    const [ isUndo , setIsUndo] = useState<boolean>(false);
    const [ isRedo , setIsRedo] = useState<boolean>(false);

    const toggleEditorStep = (type : number = 1) => {
        triggerEditorAction({
            name: type == 1 ? ActionName.TOOLSKIT_UNDO : ActionName.TOOLSKIT_REDO
        });
    }

    const toggleLayoutPanel = () => {
        // if(isShowLayout) mockupApi?.closeLayorPanel();
        // else mockupApi?.showLayorPanel(0,0);
        if(!isShowLayout){
            getLayerList(); // 
        }
        setIsShowLayout(!isShowLayout);
    }

    const getLayerList = () => {
        triggerEditorAction({
            name: ActionName.GET_LAYER_LIST
        });
    } 

    useEffect(() => {
        if(!data) return ;
        switch(data.type){
            case EventCallbackParamsType.PANEL_SCALE_CALLBACK: // 缩放
                data.data && setPanelScale(+data.data);
                break;
            case EventCallbackParamsType.GET_LAYER_LIST_CALLBACK: // 获取图层列表
                setLayerList([...(data.list || [])]) ;
                break;
            case EventCallbackParamsType.LAYER_MODIFY_CALLBACK:  // 触发图层列表需要更新
                getLayerList()
                break;
            case EventCallbackParamsType.OBJECT_SELECT: // 图像选择
                setCurrentLayerUuid((data.data as MockupObject).uuid)
                break;
            case EventCallbackParamsType.OBJECT_CANCEL_SELECT: // 取消选择
                setCurrentLayerUuid(0)
                break;
            case EventCallbackParamsType.EDITOR_CHANGE: // 编辑器有数据变化,带有是否有撤销/恢复状态
                const states = data.data ? (data.data as string).split(':') : [0,0];
                setIsUndo(states[0] == 1);
                setIsRedo(states[1] == 1);
                break;
        }
    }, [data])


    return (
        <>
            <ToolskitContext.Provider value={{
                triggerEditorAction, triggerUpdateList: getLayerList, currentLayerUuid
            }}>
                <div className="absolute z-[10000] right-[30px] bottom-[30px] flex gap-[12px]">
                    <div className={`bg-[#FFFFFF] p-[3px] rounded-[5px] shadow-[0_4px_4px_0px_rgba(0,0,0,0.25)] ${isUndo ? 'hover:bg-[#D9F2FF] cursor-pointer' : 'cursor-not-allowed'}`}
                        onClick={() => isUndo && toggleEditorStep()}>
                         <img src={ isUndo ? "/icon/icon-step-prev.png" : "/icon/icon-step-prev-disabled.png"} className="w-[25px] h-[25px]" />
                    </div>
                    <div className={`bg-[#FFFFFF] p-[3px] rounded-[5px] shadow-[0_4px_4px_0px_rgba(0,0,0,0.25)] ${isRedo ? 'hover:bg-[#D9F2FF] cursor-pointer' : ' cursor-not-allowed'}`}
                        onClick={() => isRedo && toggleEditorStep(0)}>
                        <img src={ isRedo ? "/icon/icon-step-next.png" : "/icon/icon-step-next-disabled.png"} className="w-[25px] h-[25px]" />
                    </div>
                    <div className="bg-[#FFFFFF] py-[7px] px-[10px] rounded-[5px] shadow-[0_4px_4px_0px_rgba(0,0,0,0.25)]"><span>{panelScale}%</span></div>
                    <div className="bg-[#FFFFFF] p-[3px] rounded-[5px] cursor-pointer shadow-[0_4px_4px_0px_rgba(0,0,0,0.25)] hover:bg-[#D9F2FF]"
                        onClick={toggleLayoutPanel}
                        style={{
                            ...(isShowLayout ? { background: '#D9F2FF'} : {})
                        }}><img src="/icon/icon-editor-layers.png" className="w-[25px] h-[25px]" /></div>
                </div>
                {
                    isShowLayout && <Layers list={layerList} />
                }
            </ToolskitContext.Provider>
        </>
    )
}

function Layers(props: { list?: LayerItem[]}){
    const {list} = props ;

    const dragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = "move"
    }

    const dragEnd = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    }
    
    return (
        <div className="absolute z-[9999] right-0 top-0 bg-[#FFFFFF] w-[240px] h-full p-[20px]">
            <p className="text-[16px] font-bold">Layers</p>
            <div className="mt-[10px]"
                onDragOver={dragOver}
                onDrop={dragEnd}>
                {
                    list && list.map((item : LayerItem) => (
                        <Item data={item} key={item.uuid}/>
                    ))
                }
            </div>
      </div>
    )
}

function Item(props: { data: LayerItem, isChildren ?: boolean}){
    const toolskitContext  = useToolskitContext();

    const { data, isChildren = false} = props ;
    const [ itemData, setItemData ] = useState<LayerItem>(data)
    const [ expandItems, setExpandItems ] = useState<number[]>([]);

    useEffect(() => {
        setItemData({...data, isExpand: expandItems?.filter(item => item == data.uuid).length > 0});
    },[data.children])

    const toggleShowOrHide = (event: React.MouseEvent<HTMLDivElement>) => {
        const tempData = {...itemData, isHide: !itemData.isHide}
        toolskitContext?.triggerEditorAction({
            name: ActionName.LAYER_HIDE_STATE,
            params: tempData
        })
        setItemData(tempData)
        
        event.stopPropagation();
    }

    const toggleLockedState = (event: React.MouseEvent<HTMLDivElement>) => {
        const tempData = {...itemData, isUnlocked: !itemData.isUnlocked}
        toolskitContext?.triggerEditorAction({
            name: ActionName.LAYER_LOCKED_STATE,
            params: tempData
        })
        setItemData(tempData)
        event.stopPropagation();
    }

    const toggleExpand = (event: React.MouseEvent<HTMLDivElement>) => {
        if(itemData.isExpand){
            setExpandItems(expandItems?.filter(item => item != itemData.uuid))
        }else{
            expandItems.push(itemData.uuid)
            setExpandItems([...expandItems])
        }
        setItemData({...itemData, isExpand: !itemData.isExpand})
        event.stopPropagation();
    }

    const startSort = (event: React.DragEvent<HTMLDivElement>) => {
        event.dataTransfer.effectAllowed = 'move';
        event.dataTransfer.setData('srcItemData', JSON.stringify(itemData));
    }

    const endSort = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = "move"

        const srcItemData = event.dataTransfer.getData('srcItemData');
        const srcItem = JSON.parse(srcItemData);
        const destItem = itemData ;

        let destUuid = 0 ;
        if(srcItem.parentUuid == destItem.parentUuid){ // 同一个父级，直接换
            destUuid = destItem.uuid ;
        }else if(!srcItem.parentUuid){ // 非同一个父级，则现在只能拖动一级图层(后续等Frame做完后，再来完善这个流程)
            destUuid = destItem.parentUuid ;
        }
        if(destUuid && srcItem.uuid != destUuid){
            toolskitContext?.triggerEditorAction({
                name: ActionName.LAYER_SORT,
                params: `${srcItem.uuid},${destUuid}`
            })

            setTimeout(() => {
                toolskitContext?.triggerUpdateList();
            })
        }
    }

    return (
        <>
            <div className="flex items-center gap-[5px] py-[5px] px-[5px] cursor-pointer"
                style={{
                    ...(isChildren ? { paddingLeft: 30} : {}),
                    background: itemData.uuid == toolskitContext?.currentLayerUuid ? '#D9F2FF' : '#FFFFFF'
                }}
                onClick={() => { toolskitContext?.triggerEditorAction({ name: ActionName.LAYER_POSITION, params: `${itemData.uuid}`}) }}
                draggable={true}
                onDragStart={startSort}
                onDrop={endSort}>
                {
                    itemData.children && itemData.children.length > 0 && 
                    <img src={ itemData.isExpand ? "/icon/icon-layer-down.png" : "/icon/icon-layer-right.png" } className="w-[20px] h-[20px]" 
                        onClick={toggleExpand}/>
                }
                
                <div className="grow flex items-center gap-[5px]"
                    style={{
                        ...(itemData.children && itemData.children.length ? {} : { paddingLeft: 25})
                    }}>
                    <img src={itemData.type == 1 ? "/icon/icon-layer-mockup.png" : "/icon/icon-layer-image.png"} className="w-[20px] h-[20px]" />
                    <span className="text-[14px] font-bold">{itemData.type == 1 ? 'Mockup' : 'Image'}</span>
                </div>
                <img src={itemData.isHide ? "/icon/icon-layer-hide.png" : "/icon/icon-layer-show.png"} className="w-[20px] h-[20px] " 
                        onClick={toggleShowOrHide}/>
                <img src={itemData.isUnlocked ? "/icon/icon-layer-unlocked.png" : "/icon/icon-layer-locked.png"} className="w-[20px] h-[20px]" 
                        onClick={toggleLockedState}/>
            </div>
            {
                itemData.children && itemData.isExpand && 
                itemData.children.map((item: LayerItem) => (
                    <Item data={item} isChildren={true} key={item.uuid}/>
                ))
            }
        </>
    )
}


export type LayerItem = {
    uuid: number,
    parentUuid: number,
    type: number,
    isExpand?: boolean,
    isHide ?: boolean,
    isUnlocked ?: boolean,
    children?: LayerItem[]
}