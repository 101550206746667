import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import Project from './pages'
import History from './pages/history'
import ProjectEditor from 'pages/project/editor/index'
import CreditRule from 'pages/creditRule'
import FreeCredit from 'pages/freeCredit'
export default function App(){
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Project />} />
                    <Route path="/history" element={<History />} />
                    <Route path="/credit/rule" element={<CreditRule />} />
                    <Route path="/credit/free" element={<FreeCredit />} />
                    <Route path="/project/:id" element={<ProjectEditor />} />
                    {/* 未匹配，则跳转Index页面 */}
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </BrowserRouter>
        </>
    )
}