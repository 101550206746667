import ProjectEditorDetail from "./detail";
import Global from "components/common/global";
import GuideLayout from "context/guide";
import CryptoJS from 'crypto-js';

window.CryptoJS = CryptoJS ;

export default function ProjectEditor(){
    return (
        <Global>
            <GuideLayout>
                <ProjectEditorDetail />
            </GuideLayout>
        </Global>
    )
}