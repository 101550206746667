import Layout from "components/base/layout";
import Contact from "components/common/contact";
import { useLoadingContext } from "context/loadingLayout";
import { useEffect, useState } from "react";

export default function FreeCredit() {
  return (
    <Layout isHasMenuBar={false} isHasSearch={false}>
      <Content />
    </Layout>
  );
}

function Content() {
  const loadingContext = useLoadingContext();
  const [isContact, setIsContact] = useState<boolean>(false);

  useEffect(() => {
    if(loadingContext?.isLoadingLayout) loadingContext?.setIsLoadingLayout(false);
  },[])

  const contact = () => {
    setIsContact(true);
  }
  return (
    <>
      <div className="flex justify-center">
        <div className="w-[1000px]">
          <h2 className="text-[24px] w-full text-center font-bold mt-[30px]">Get Free Credits</h2>
          <p className="text-[16px] font-semibold mt-[20px]">Make your valuable suggestions.</p>
          <p className="mt-[20px] text-[14px]">You can send email to raise your questions and suggestions about the existing features of mockuplabs, and you can also raise any new features that you think we need to develop in the future.</p>
          <p className="mt-[10px] text-[14px]">We will humbly accept and try our best to meet your needs, so that you can use it better in the long run.</p>
          <p className="mt-[10px] text-[14px] text-[#787878]">Heads up: When we receive the email, we will reply you within 48 hours and give you 50 credits.</p>
          <button className="flex mt-[10px] rounded-[5px] bg-[#14A5FA] text-[#FFFFFF] px-[12px] py-[5px] text-[14px]"
            onClick={contact}>Contact us</button>
          
          {/* <p className="text-[16px] font-semibold mt-[20px]">2. Shopify Reviews</p>
          <p className="mt-[20px] text-[14px]">Leave a positive review of our APP in Shopify and get 50 free credits.</p>
          <p className="mt-[10px] text-[14px] text-[#787878]">Heads up: When our APP receives a positive review, it will send points to your account according to your store name and notify you by email.</p>
          <p className="mt-[20px] text-[14px]">Credits earned for free through the above methods do not expire.</p> */}
        </div>
      </div>
      {
        isContact && <Contact cancel={() => setIsContact(false)} />
      }
    </>
  );
}