import Loading from "../../../components/common/loading";
import { DragEventHandler, ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { useHttpUtil , useHttpContext} from "context/httpContext";
import { useFetcher } from "react-router";
import Tools from "libs/utils/tools";

type Material = {
    mid: string,
    name: string,
    url: string,
    width?: number,
    height?: number,
    status?: number,
    progress?:number,
    isChecked ?: boolean,
    column?:number
}

interface CheckedMaterial {
    [key: string]: boolean
}

const PAGE_SIZE = 15 ;
export default function EditorMeunBar(props: { onImageClick: Function, switchShowCallback: Function}){
    const { onImageClick, switchShowCallback} = props ;
    // 左侧工具栏的选择类型，0为未选择 1开始
    const [toolType, setToolType] = useState<number>(1);
    const httpUtil = useHttpUtil();
    const httpContent = useHttpContext();

    // 素材库分页索引
    const [materialPage, setMaterialPage] = useState<number>(-1);

    const [checkedMaterial, setCheckedMaterial] = useState<CheckedMaterial>({});

    const [materialList, setMaterialList] = useState<Material[]>();

    const [uploadMaterial, setUploadMaterial] = useState<Material>();

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const listElement = useRef<HTMLDivElement>(null);

    useEffect(() => {
        materialPage > 0 && getMaterialList();
    },[materialPage]);

    useEffect(() => {
        if(toolType == 1 && materialPage == -1){
            setMaterialPage(1);
        }
    }, [toolType, materialPage]);

    useEffect(() => {
        switchShowCallback(toolType == 1)
    },[ toolType ])

    const getMaterialList = async function(){
        setIsLoading(true);
        
        const responseData = await httpUtil.post('/biz/material/list', {
            currentPage: materialPage,
            pageSize : PAGE_SIZE
        })
        if(!responseData.data || responseData.data.length == 0) {
            setMaterialPage(0);
            setMaterialList([]);
        }else{
            if(materialPage == 1){
                parseListHeight(responseData.data)
            }else{
                parseListHeight(materialList?.concat(responseData.data))
            }
            if(responseData.data.length < PAGE_SIZE){
                setMaterialPage(0);
            }
        } 
        setTimeout(() => {
            setIsLoading(false);
        },1500)
        
    }

    const uploadFile = async function(e: React.ChangeEvent<HTMLInputElement>){
        const files = e.target.files ;
        if(files?.length){
            const file = files[0];
            const MAX_M = 10 ;
            if(file.size >= MAX_M * 1024 * 1024){
                return httpContent?.response(1, `The image size exceeds the maximum limit and cannot be larger than ${MAX_M}M`);
            }
            

            const reader = new FileReader();
            let item : Material =  {
                mid : new Date().getTime() + '',
                name : 'upload',
                url : '',
                status: 1,
                progress: 0
            }
            reader.onload = function(e) {
                item = {
                    ...item,
                    url: e.target?.result as string
                }
                setUploadMaterial(item);

                // 重新计算左右列
                if(e.target && materialList){
                    const img = new Image();
                    img.src = e.target.result as string;
                    img.onload = function(){
                        item.width = img.width ;
                        item.height = img.height ;
                        parseListHeight(([] as Material[]).concat(materialList), item);
                    }
                }
            }
            reader.readAsDataURL(file);

            const responseData = await httpUtil.upload(file, (progress: number) => {
                setUploadMaterial({
                    ...item,
                    progress : 10
                })
            });

            const material = responseData.data as Material;
            await httpUtil.image(material.url, (progress: number) => {
                if(progress >= 100){
                    setUploadMaterial(undefined);
                    props.onImageClick(material.url)
                    material.column = 0 ;
                    if(materialList){
                        parseListHeight([material].concat(materialList))
                    }else{
                        parseListHeight([material]);
                    }
                    return 
                }
                const tmpProgress = Math.min(progress + 10, 95)
                setUploadMaterial({
                    ...item,
                    progress : tmpProgress
                })

            })
            // props.onImageClick(responseData.data)

            // setMaterialPage(1);
            // materialPage == 1 && getMaterialList();
        }
    }

    const scrollContent = async function () {
        if(listElement.current && materialPage > 0 && !isLoading){
            const { scrollTop, scrollHeight, clientHeight} = listElement.current ;
            if(scrollTop + clientHeight + 20 > scrollHeight){
                setMaterialPage(materialPage + 1)
            }
        }
    }

    const checkedHandler = function(mid: string){
        const tmp = {...checkedMaterial} || {} ;
        if(tmp[mid]){
            delete tmp[mid] ;
        }else{
            tmp[mid] = true ;
        }
        setCheckedMaterial(tmp);
    }

    const deleteSelected = async function(ids?: string[]){
        if(!ids){
            ids = checkedMaterial && Object.keys(checkedMaterial) || [] ;
            if(!ids.length) return ;
        }
        
        await httpUtil.post('/biz/material/del', {
            ids: ids.join(',')
        });
        clearSelected();
        listElement.current?.scrollTo({
            top: 0
        })
        if(materialPage != 1) setMaterialPage(1);
        else getMaterialList() ;
    }

    const clearSelected = function(){
        setCheckedMaterial({});
    }

    const download = (url: string, name: string) => {
        httpUtil.download(Tools.completeUrl(url), name);
    }

    const parseListHeight = function(list: Material[] | undefined, upload ?: Material){
        if(!list) return ;
        const columnHeight = [0, 0], columnWidth = 50 ;
        if(upload && upload.width && upload.height){
            columnHeight[0] = columnWidth / (upload.width / upload.height)
        }
        list.map((item: Material) => {
            item.column = columnHeight[0] <= columnHeight[1] ? 0 : 1
            const {width = 0, height = 1} = item ;
            const ratio = width / height ;
            const itemHeight = columnWidth / ratio ;
            if(columnHeight[0] <= columnHeight[1]) columnHeight[0] += itemHeight;
            else columnHeight[1] += itemHeight ;
        })
        setMaterialList(list);
    }

    const imgDragStart = (event: React.DragEvent<HTMLImageElement>, url: string) => {
        event.dataTransfer.effectAllowed = 'copy';
        event.dataTransfer.setData('imageUrl', url);
    }
    const dragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = "copy"
    }

    const buildItem = function(item : Material, targetColumn: number) : ReactNode {
        if(item.column !== targetColumn) return null ;
        const {mid, name, url, width = 0, height = 1, isChecked = checkedMaterial && checkedMaterial[mid] ? true : false} = item ;

        return <div className="relative group rounded-[10px] bg-[#EBEBEB] w-full cursor-pointer mt-[10px]" key={mid}
                style={{ 
                    aspectRatio: width / height,
                }}>
                <div className={`rounded-[10px] overflow-hidden `}
                    style={{ 
                        border: isChecked ? '2px solid #14A5FA' : '#BFBFBF'
                    }}>
                    <img src={url} alt={name} className={` w-full bg-[#FFFFFF] cursor-pointer active:cursor-default `} 
                        onClick={() => props.onImageClick(url)}
                        draggable={true}
                        onDragStart={(event) => { imgDragStart(event, url) }}
                        />
                </div>
                <div
                    style={{
                        backgroundColor: isChecked ? '#14A5FA' : '#FFFFFF',
                        borderWidth: isChecked ? '0px' : '1px',
                        display: checkedMaterial && Object.keys(checkedMaterial).length ? 'block': 'none'
                    }} 
                    className="absolute top-[10px] left-[10px] p-[1px] border border-[#BFBFBF] rounded-[5px] w-[20px] h-[20px] group-hover:!block hidden"
                    onClick={() => {
                        checkedHandler(mid)
                    }}>
                {
                    isChecked && <img src="/icon/icon-checked.png"/>
                    || <img src="/icon/icon-not-checked.png" className="group-hover:block hidden"/>
                }
                </div>
                
                <div className="absolute group/edit z-[9999999] top-[10px] right-[10px] bg-[#797979] rounded-[5px] w-[20px] h-[20px] text-center p-[2px] cursor-pointer group-hover:block hidden ">
                    <img src="/icon/icon-more.png" className="w-[18px]"/>

                    <div className="absolute z-[9999999] w-[140px] top-[20px] pt-[10px] group-hover/edit:block hidden"
                        style={{
                            ...(targetColumn == 0 ? { left : -10} : { right: -10})
                        }}>
                        <div className=" bg-[#FFFFFF] rounded-[10px] p-[10px] flex flex-col gap-[5px] shadow-[0_4px_4px_0px_rgba(0,0,0,0.25)] ">
                            <div className="flex items-center py-[6px] px-[10px] gap-[10px] rounded-[5px] hover:bg-[#D9F2FF] hover:text-[#000000]"
                                onClick={() => download(url, item.name)}>
                                <img src="/icon/icon-download.png" className="w-[18px] h-[18px]" />
                                <span>Download</span>
                            </div>
                            <div className="flex items-center py-[6px] px-[10px] gap-[10px] rounded-[5px] hover:bg-[#D9F2FF] hover:text-[#000000]"
                                onClick={() => deleteSelected([mid])}>
                                <img src="/icon/icon-delete.png" className="w-[18px] h-[18px]" />
                                <span>Delete</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
    }

    return (
        <div className="relative flex w-[100px] flex-none flex-col items-center p-[15px]">
            <div className={`flex flex-col items-center justify-center w-[70px] h-[70px] rounded-[10px] cursor-pointer ${toolType == 1 ? ' bg-[#D9F2FF]' : ''}`}
                onClick={() => setToolType(toolType == 1 ? 0 : 1)}>
                <img src="/icon/icon-editor-media.png" className="w-[24px]" />
                <span>Media</span>
            </div>
            {
                toolType == 1 && 
                <div className="absolute h-full left-[100px] top-[0px] w-[320px]  shadow-lg bg-[#FFFFFF]"
                    onDragOver={event => dragOver(event)}>
                    <div className="relative flex flex-col h-full w-full p-[10px] overflow-y-auto z-[10001]  bg-[#FFFFFF]"
                        ref={listElement}
                        onScroll={() => scrollContent()}>
                        <div className="flex p-[5px] pb-[0px]">
                            <div id="step1_element" className="flex relative w-full items-center justify-center rounded-[10px] bg-gradient-to-r from-[#14A5FA] to-[#10F28D] cursor-pointer text-[#FFFFFF] text-[14px] py-[10px] gap-[10px]">
                                <img src="/icon/icon-editor-upload.png" className="w-[20px]" />
                                <span>Upload</span>
                                <input type="file" className="absolute left-0 top-0 w-full h-full opacity-0 cursor-pointer" 
                                    accept="image/png, image/jpeg"
                                    onChange={(e) => uploadFile(e)}/>
                            </div>
                        </div>
                        <div className="flex flex-wrap items-start">
                            <div className="basis-1/2 p-[5px]">
                                {
                                    uploadMaterial && 
                                        <div className="rounded-[10px] relative mt-[10px]">
                                            <img src={uploadMaterial.url} alt={uploadMaterial.name} className={`rounded-[10px] cursor-pointer`} />
                                            <div className="absolute w-full h-full left-0 top-0 bg-[#000000]/[0.5] rounded-[10px] px-[18px] flex items-center justify-center">
                                                <div className="w-full h-[10px] relative bg-[#FFFFFF] rounded-[10px]">
                                                    <div style={{
                                                        width : `${uploadMaterial.progress}%`
                                                    }} className="absolute scale-[1.02] left-0 top-0 w-[0%] h-full rounded-[10px] bg-[#14A5FA]"></div>
                                                </div>
                                            </div>
                                        </div>
                                }
                                {
                                    materialList?.map((item: Material) => (
                                        buildItem(item, 0)
                                    ))
                                }
                            </div>
                            <div className="basis-1/2 p-[5px]">
                                {
                                    materialList?.map((item: Material) => (
                                        buildItem(item, 1)
                                    ))
                                }
                            </div>
                            
                        </div>
                        {
                            isLoading && <Loading />
                        }
                        {
                            !isLoading && !uploadMaterial && (!materialList || !materialList.length) ?
                                <div className="flex w-full h-full justify-center">
                                    <div className="text-center mt-[120px]">
                                        <img src="/icon/icon-empty.png" className="w-[200px]" />
                                        <p className="text-[18px] text-[#BFBFBF] ">No Files</p>
                                    </div>
                                </div>
                            : null
                        }
                    </div>
                    {
                        checkedMaterial && Object.keys(checkedMaterial).length ?
                            <div className="absolute w-full px-[20px] bottom-[15px] left-0 z-[10002]">
                                <div className="w-full border py-[10px] px-[15px] border-[#D9D9D9] rounded-[5px] bg-[#FFFFFF] flex items-center">
                                    <span className="text-[14px]">{Object.keys(checkedMaterial).length} selected</span>
                                    <div className="grow flex justify-center ">
                                        <img src="/icon/icon-delete.png" className="w-[25px] cursor-pointer " 
                                            onClick={() => deleteSelected()} />
                                    </div>
                                    <div className="">
                                        <img src="/icon/icon-close.png" className="w-[20px] cursor-pointer" 
                                            onClick={() => clearSelected()} />
                                    </div>
                                </div>
                            </div>  
                        : null
                    }
                </div>
            }
                    
        </div>
    )
}