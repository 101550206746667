import Bubble from 'components/common/bubble';
import Slider from 'components/common/slider';
import { useEffect, useState } from 'react';
import { ChromePicker, ColorResult} from 'react-color'
import { Action, ActionName, SmartColorFineTuningObject, MockupObject, EventCallbackParamsType, EventCallbackParams } from './mockupEditor';

type ColorPicker = {
    isShow : boolean,
    color : string
}


/**
 * 智能换色
 * @param props 属性
 * @param callback 修改属性后的回调
 * @returns 
 */
export default function SmartColor(props: {editorCallbackData: EventCallbackParams | undefined, 
    triggerEditorAction: (action: Action) => void, isQuickCalcBar : boolean, finishCallback: () => void}){
    const {editorCallbackData, triggerEditorAction, isQuickCalcBar, finishCallback} = props ;

    // 属性颜色值是否展开
    const [isColorExpand, setIsColorExpand] = useState<boolean>(false);
    // 其他属性是否展开
    const [isFineTuningExpand, setIsFineTuningExpand] = useState<boolean>(false);

    //  属性面板是否可用
    const [ isPanelEnable, setIsPanelEnable ]= useState<boolean>(false);
    // 颜色选择面板是否显示
    const [ isShowColorPanel, setIsShowColorPanel ]= useState<boolean>(false);

    // 功能按钮类型，0：初始值  1：click  2: paint  3: reset
    const [ funcType, setFuncType ] = useState<number>(0);

    // paint的按钮类型
    const [ paintType, setPaintType ]= useState<number>(0);

    // 画笔大小
    const [ penWidth, setPenWidth ] = useState<number>(10);

    // click时会触发分割服务，是否正在加载
    const [ isLoadingClick, setIsLoadingClick ] = useState<boolean>(false);
    
    // 属性
    const [fineTuning, setFineTuning] = useState<SmartColorFineTuningObject>(() => {
        return {
            opacity: 0, // 透明度
            saturation: 0, // 饱和度
            brightness: 0, // 亮度
            contrast:0  // 对比度
        }
    });

    useEffect(() => {
        // console.info('come')
        // controlSmartColorFunc();
        return () => {
            // console.info('leave')
        }
    }, [])

    // 监听面板相关数据
    useEffect(() => {
        if(!editorCallbackData) return ;
        switch(editorCallbackData.type){
            case EventCallbackParamsType.SMART_COLOR_AI_READY: // click调用分割服务已完成，就绪
                setIsLoadingClick(false);
                break;
            case EventCallbackParamsType.SMART_COLOR_CHOICE_STATUS: // 智能换色选择状态
                setIsPanelEnable(editorCallbackData.data == '1')
                break;
            case EventCallbackParamsType.GET_PEN_SIZE_CALLBACK: // 画笔大小
                editorCallbackData.data && setPenWidth(+editorCallbackData.data)
                break;
        }
    }, [editorCallbackData])

    const toggleColorExpand = () => {
        setIsColorExpand(!isColorExpand)
    }

    const toggleFineTuningExpand = () => {
        setIsFineTuningExpand(!isFineTuningExpand)
    }

    // 开启智能换色
    const controlSmartColorFunc = () => {
        funcType == 0 && changeFuncType(1);
    }

    // 切换类型
    const changeFuncType = (type: number) => {
        if(isLoadingClick) return ;
        setFuncType(type);
    }

    useEffect(() => {
        setIsPanelEnable(funcType == 2);

        if(funcType == 0) return ;
        if(funcType == 1){
            triggerEditorAction({
                name : ActionName.SMART_COLOR_START,
            })
            setIsLoadingClick(true);
        }else if(funcType == 2){
            triggerEditorAction({
                name : ActionName.SMART_COLOR_MAN_START,
            })
        }
    },[ funcType ])

    useEffect(() => {
        if(paintType == 0) return ;
        setTimeout(() => {
            triggerEditorAction({
                name : ActionName.SMART_COLOR_MAN_SWITCH_TYPE,
                params: `${paintType == 1 ? 1 : 0}`
            })
        },50)
    },[ paintType ])

    // 重置 
    const reset = () => {
        if(isLoadingClick) return ;
    }

    // 保存
    const save = () => {
        if(isLoadingClick) return ;
        if(funcType == 1){
            triggerEditorAction({
                name : ActionName.SMART_COLOR_PROCESS,
            })
        }else if(funcType == 2){
            triggerEditorAction({
                name : ActionName.SMART_COLOR_MAN_PROCESS,
            })
        }
        finishCallback();
    }

    const changePenWidth = (size: number) => {
        triggerEditorAction({
            name: ActionName.SETTING_PEN_SIZE,
            params: `${size}`
        })
    }
    
    const selectedColor = (color: string) => {
        setIsShowColorPanel(true);
        change({...fineTuning, color});
    }

    const changeColor = (color: ColorResult) => {
        // hex hsl hsv rgb
        const {hex} = color ;
        selectedColor(hex);
    }

    const change = (changeData: SmartColorFineTuningObject) => {
        // console.info(changeData)
        setFineTuning(changeData);

        if(funcType == 1){
            triggerEditorAction({
                name : ActionName.SMART_COLOR_SETTING,
                params: changeData
            })
        }else if(funcType == 2){
            triggerEditorAction({
                name : ActionName.SMART_COLOR_MAN_UPDATE,
                params: changeData.color
            })
        }
    }

    const calcValue = (value: number, step: number, min : number = -1, max : number = 1) => {
        if(step != 0) value = parseFloat((value + step).toFixed(2)) ;
        return Math.max(min, Math.min(max, value))
    }

    const calc = (key: string, step: number) => {
        if(key === 'opacity') return change({...fineTuning, opacity: calcValue(fineTuning.opacity, step, 0)});
        if(key === 'saturation') return change({...fineTuning, saturation: calcValue(fineTuning.saturation, step)});
        if(key === 'brightness') return change({...fineTuning, brightness: calcValue(fineTuning.brightness, step)});
        if(key === 'contrast') return change({...fineTuning, contrast: calcValue(fineTuning.contrast, step)});
    }

    return (
        <>
            {
                isShowColorPanel &&
                <div
                    className="absolute z-[1203] right-[243px] top-[110px]"
                    onClick={(e) => e.stopPropagation()}
                    onMouseDown={(e) => e.stopPropagation()}
                    onMouseMove={(e) => {e.stopPropagation(); e.preventDefault()}}
                    onMouseUp={(e) =>{e.stopPropagation(); e.preventDefault()}}
                    onMouseOver={(e) => e.stopPropagation()}>
                    <ChromePicker color={fineTuning.color} disableAlpha={true} className="rounded-[5px]" 
                        onChangeComplete={(color) => changeColor(color)} />
                </div>
            }
            <div className="absolute z-[1201] right-0 top-0 bg-[#FFFFFF] w-[240px] h-full p-[20px]"
                onClick={() => setIsShowColorPanel(false)}>
                <p className="text-[16px] font-bold">Smart Color Change</p>
                <div className="relative flex flex-wrap mt-[20px] gap-[10px]"
                    style={{
                        color: isPanelEnable ? '#000000' : '#BFBFBF'
                    }}>
                        {
                            !isPanelEnable &&
                            <div className='absolute z-[10] left-0 top-0 w-full h-full cursor-not-allowed'></div>
                        }
                    <div className="w-full">
                        <div className="flex w-full py-[10px] cursor-pointer"
                            onClick={toggleColorExpand}>
                            <span className='font-bold text-[14px] grow'>Specify color</span>
                            <img src={isColorExpand ? `/icon/icon-layer-down${isPanelEnable ? '' : '-disabled'}.png` : `/icon/icon-layer-right${isPanelEnable ? '' : '-disabled'}.png`} className='w-[20px] h-[20px]'/>
                        </div>
                        {
                            isColorExpand && 
                            <div className='mt-[10px] w-[80px] h-[80px] rounded-[10px] flex items-center justify-center cursor-pointer'
                                style={{
                                    backgroundColor: fineTuning?.color || '#A30707'
                                }}
                                onClick={(e) => {e.stopPropagation();selectedColor('#A30707')}}>
                                <img src='/icon/icon-create-new-white.png' className='w-[21px] h-[21px]' />
                            </div>
                        }
                    </div>
                    {
                        funcType != 2 && 
                        <div className="w-full">
                            <div className="flex w-full py-[10px] cursor-pointer"
                                onClick={toggleFineTuningExpand}>
                                <span className='font-bold text-[14px] grow'>Manual fine-tuning</span>
                                <img src={isFineTuningExpand ? `/icon/icon-layer-down${isPanelEnable ? '' : '-disabled'}.png` : `/icon/icon-layer-right${isPanelEnable ? '' : '-disabled'}.png`} className='w-[20px] h-[20px]'/>
                            </div>
                            {
                                isFineTuningExpand && 
                                <div className=''>
                                    {/* <div className="w-full mt-[10px] flex items-center">
                                        <p className="text-[14px] basis-1/2 font-semibold">Transparency</p>
                                        <div className="relative text-right basis-1/2">
                                            <img src="/icon/icon-sub.png" className="absolute w-[20px] h-[20px] right-[58px] top-[3px] cursor-pointer" 
                                                onClick={() => calc('opacity', -0.05)}/>
                                            <input type="number" className="text-center px-[20px] py-[3px] border border-[#D9D9D9] rounded-[5px] w-[80px]"
                                                step={0.05} max={1} min={0} value={fineTuning.opacity} 
                                                onChange={(e) => change({...fineTuning, opacity: calcValue(+e.target.value, 0, 0)})}  />
                                            <img src="/icon/icon-plus.png" className="absolute w-[20px] h-[20px] right-[2px] top-[3px] cursor-pointer" 
                                                onClick={() => calc('opacity', 0.05)}/>
                                        </div>
                                    </div> */}
                                    <div className="w-full mt-[10px] flex">
                                        <p className="text-[14px] basis-1/2 font-semibold flex items-center">Saturation</p>
                                        <div className="relative text-right basis-1/2">
                                            <img src="/icon/icon-sub.png" className="absolute w-[20px] h-[20px] right-[58px] top-[3px] cursor-pointer" 
                                                onClick={() => calc('saturation', -0.05)}/>
                                            <input type="number" className="text-center px-[20px] py-[3px] border border-[#D9D9D9] rounded-[5px] w-[80px]"
                                                step={0.05} max={1} min={-1} value={fineTuning.saturation} 
                                                onChange={(e) => change({...fineTuning, saturation: calcValue(+e.target.value, 0, -1)})}  />
                                            <img src="/icon/icon-plus.png" className="absolute w-[20px] h-[20px] right-[2px] top-[3px] cursor-pointer" 
                                                onClick={() => calc('saturation', 0.05)}/>
                                        </div>
                                    </div>
                                    <div className="w-full mt-[20px] flex">
                                        <p className="text-[14px] basis-1/2 font-semibold flex items-center">Contrast</p>
                                        <div className="relative text-right basis-1/2">
                                            <img src="/icon/icon-sub.png" className="absolute w-[20px] h-[20px] right-[58px] top-[3px] cursor-pointer" 
                                                onClick={() => calc('contrast', -0.05)}/>
                                            <input type="number" className="text-center px-[20px] py-[3px] border border-[#D9D9D9] rounded-[5px] w-[80px]"
                                                step={0.05} max={1} min={-1} value={fineTuning.contrast} 
                                                onChange={(e) => change({...fineTuning, contrast: calcValue(+e.target.value, 0, -1)})}  />
                                            <img src="/icon/icon-plus.png" className="absolute w-[20px] h-[20px] right-[2px] top-[3px] cursor-pointer" 
                                                onClick={() => calc('contrast', 0.05)}/>
                                        </div>
                                    </div>
                                    <div className="w-full mt-[20px] flex">
                                        <p className="text-[14px] basis-1/2 font-semibold flex items-center">Luminance</p>
                                        <div className="relative text-right basis-1/2">
                                            <img src="/icon/icon-sub.png" className="absolute w-[20px] h-[20px] right-[58px] top-[3px] cursor-pointer" 
                                                onClick={() => calc('brightness', -0.05)}/>
                                            <input type="number" className="text-center px-[20px] py-[3px] border border-[#D9D9D9] rounded-[5px] w-[80px]"
                                                step={0.05} max={1} min={-1} value={fineTuning.brightness} 
                                                onChange={(e) => change({...fineTuning, brightness: calcValue(+e.target.value, 0, -1)})}  />
                                            <img src="/icon/icon-plus.png" className="absolute w-[20px] h-[20px] right-[2px] top-[3px] cursor-pointer" 
                                                onClick={() => calc('brightness', 0.05)}/>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                    {/* <div className='w-full mt-[20px]'>
                        <button className="w-full text-center text-[14px] py-[10px] border border-[#D9D9D9] rounded-[10px]">
                            <span>Removal effects</span>
                        </button>
                    </div> */}
                </div>
            </div>

            <div className='absolute z-[1001] flex flex-col items-center gap-[5px]' style={{
                left: (isQuickCalcBar ? 420 : 0 ) + 30,
                bottom: 30
            }}>
                {
                    funcType == 2 && 
                    <div className='flex w-[200px] items-center px-[10px] py-[6px] gap-[5px] bg-[#FFFFFF] rounded-[5px]'>
                        <div className='group cursor-pointer'
                            onClick={() => setPaintType(1)}>
                            {
                                paintType != 2 ?
                                    <img src='/icon/icon-smart-color-plus-active.png' className='w-[20px] h-[20px]' />
                                :
                                    <>
                                        <img src='/icon/icon-smart-color-plus.png' className='w-[20px] h-[20px] group-hover:hidden block' />
                                        <img src='/icon/icon-smart-color-plus-active.png' className='w-[20px] h-[20px] group-hover:block hidden' />
                                    </>
                            }
                        </div>
                        <div className='group cursor-pointer'
                            onClick={() => setPaintType(2)}>
                            {
                                paintType == 2 ?
                                    <img src='/icon/icon-smart-color-sub-active.png' className='w-[20px] h-[20px]' />
                                :
                                    <>
                                        <img src='/icon/icon-smart-color-sub.png' className='w-[20px] h-[20px] group-hover:hidden block' />
                                        <img src='/icon/icon-smart-color-sub-active.png' className='w-[20px] h-[20px] group-hover:block hidden' />
                                    </>
                            }
                            
                        </div>
                        <Slider init={penWidth} changeHandler={changePenWidth} />
                    </div>
                }
                
                <div className='px-[10px] py-[5px] rounded-[10px] flex gap-[10px] items-center bg-[#FFFFFF] shadow-[0_4px_4px_0px_rgba(0,0,0,0.25)]'>
                    <div className='relative group p-[5px] rounded-[50%] bg-[#14A5FA] cursor-pointer'>
                        <img src='/icon/icon-smart-color-ai.png' className='w-[20px] h-[20px]' />
                        <div className={`absolute w-[360px] left-[-10px] bottom-[53px] z-[9999] px-[22px] py-[20px] bg-[#000000] hidden text-[#ffffff] rounded-[10px] group-hover:block`}>
                            <div className="absolute left-[25px] bottom-[-15px] translate-y-[-5px] translate-x-[-50%] w-[20px] h-[20px] rotate-45 bg-[#000000]"></div>
                            <p className="font-bold text-[14px]">Select Area Tool</p>
                            <p className='mt-[10px]'>Click: Smartly sort out the area, you can select it directly by clicking on it.</p>
                            <p className='mt-[10px]'>Paint: Hold down the mouse to paint the area, suitable for adding or erasing edges.</p>
                        </div>
                    </div>
                    <div className='flex group items-center gap-[5px] px-[5px] py-[5px] cursor-pointer hover:text-[#14A5FA]'
                        style={{
                            ...(funcType == 1 ? { color: '#14A5FA'} : {})
                        }}
                        onClick={() => changeFuncType(1)}>
                        {
                            funcType == 1 ?
                                <img src='/icon/icon-smart-color-click-active.png' className='w-[20px] h-[20px]' />
                            :
                                <>
                                    <img src='/icon/icon-smart-color-click.png' className='w-[20px] h-[20px] group-hover:hidden block' />
                                    <img src='/icon/icon-smart-color-click-active.png' className='w-[20px] h-[20px] group-hover:block hidden' />
                                </>
                        }
                        
                        <span className='text-[14px]'>Click</span>
                    </div>
                    <div className='flex group items-center gap-[5px] px-[5px] py-[5px] cursor-pointer hover:text-[#14A5FA]'
                        style={{
                            ...(funcType == 2 ? { color: '#14A5FA'} : isLoadingClick ? {color:'#dbdbdb'} : {})
                        }}
                        onClick={() => changeFuncType(2)}>
                        {
                            funcType == 2 ?
                                <img src='/icon/icon-smart-color-paint-active.png' className='w-[20px] h-[20px]' />
                            :
                                isLoadingClick ?
                                    <img src='/icon/icon-smart-color-paint-disabled.png' className='w-[20px] h-[20px]' />
                                :
                                <>
                                    <img src='/icon/icon-smart-color-paint.png' className='w-[20px] h-[20px] group-hover:hidden block' />
                                    <img src='/icon/icon-smart-color-paint-active.png' className='w-[20px] h-[20px] group-hover:block hidden' />
                                </>
                        }
                        <span className='text-[14px]'>Paint</span>
                    </div>
                    <div className='flex group items-center gap-[5px] px-[5px] py-[5px] cursor-pointer hover:text-[#14A5FA]'
                        onClick={reset}
                        style={{
                            ...(isLoadingClick ? {color:'#dbdbdb'}:{})
                        }}>
                        {
                            isLoadingClick ?
                                <img src='/icon/icon-smart-color-reset-disabled.png' className='w-[20px] h-[20px]' />
                            :
                                <>
                                    <img src='/icon/icon-smart-color-reset.png' className='w-[20px] h-[20px] group-hover:hidden block' />
                                    <img src='/icon/icon-smart-color-reset-active.png' className='w-[20px] h-[20px] group-hover:block hidden' />
                                </>
                        }
                        <span className='text-[14px]'>Reelect</span>
                    </div>
                    <div className='flex group items-center gap-[5px] px-[5px] py-[5px] cursor-pointer hover:text-[#14A5FA]'
                        onClick={save}
                        style={{
                            ...(isLoadingClick ? {color:'#dbdbdb'}:{})
                        }}>
                        {
                            isLoadingClick ?
                                <img src='/icon/icon-save-disabled.png' className='w-[20px] h-[20px]' />
                            :
                                <>
                                    <img src='/icon/icon-save.png' className='w-[20px] h-[20px] group-hover:hidden block' />
                                    <img src='/icon/icon-save-active.png' className='w-[20px] h-[20px] group-hover:block hidden' />
                                </>
                        }
                        <span className='text-[14px]'>Perform</span>
                    </div>
                </div>
            </div>
        </>
    )
}