import { useProject } from "../../hooks/useProject";
import { useEffect, useLayoutEffect, useRef, useState } from "react"
import List from "../base/list"
import { useLoadingContext } from "../../context/loadingLayout";
import { useSearchBarContext } from "../../context/search";
import DialogConfirm from "../common/dialogConfirm";
import ListItem from "../common/listItem"
import { useHttpUtil } from "context/httpContext";
import Tools from "libs/utils/tools";
import Loading from "components/common/loading";

type Images = {
    imageId: string,
    url: string,
    projectId: string,
    projectName: string,
    isFavorites: number,
    aspectRatio: string,
}
const PAGE_SIZE = 20 ;
export default function HistoryList(){
    const [, gotoEditor] = useProject();
    const loadingContext = useLoadingContext();
    const searchBarContext = useSearchBarContext();
    const httpUtil = useHttpUtil();

    const [list, setList] = useState<Images[]>();
    const [tabType, setTabType] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [detailItem, setDetailItem] = useState<Images>();
    const [detailItemIndex, setDetailItemIndex] = useState<number>(0);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isDelete, setIsDelete] = useState<boolean>(false);
    const listElement = useRef<HTMLDivElement>(null);

    useEffect(() => {
        currentPage > 0 && getList()
    }, [currentPage])

    useEffect(() => {
        if(searchBarContext?.keyword !== null) currentPage == 1 ? getList() : setCurrentPage(1);
    }, [searchBarContext?.keyword])

    const convertType = (type: number) =>{
        setTabType(type);
        currentPage == 1 ? getList() : setCurrentPage(1);
    }

    const getList = async () => {
        if(currentPage < 1 || isLoading) return ;
        setIsLoading(true);
        const data = await httpUtil.post('/biz/images/list', {
            type : tabType,
            pageSize: PAGE_SIZE,
            currentPage,
            keyword: searchBarContext?.keyword || ''
        })
        // setList(data.data);
        // setIsLoading(false);

        if(loadingContext?.isLoadingLayout) loadingContext?.setIsLoadingLayout(false);


        let isContinueLoad = true ;
        if(!data.data || currentPage == 1 && data.data.length == 0) {
            setCurrentPage(0);
            setList([]);
            isContinueLoad = false ;
        }else{
            if(currentPage == 1){
                setList(data.data)
            }else{
                setList(list?.concat(data.data))
            }
            if(data.data.length < PAGE_SIZE){
                setCurrentPage(0);
                isContinueLoad = false ;
            }
        } 
        setIsLoading(false);
        if(isContinueLoad){
            setTimeout(() => {
                scrollContent();
            },500)
        }

    }

    const scrollContent = async function () {
        if(listElement.current && currentPage > 0 && !isLoading){
            const { scrollTop, scrollHeight, clientHeight} = listElement.current ;
            if(scrollTop + clientHeight + 20 > scrollHeight){
                setCurrentPage(currentPage + 1)
            }
        }
    }

    const showDetail = (index: number) => {
        if(list){
            const item = list[index];
            setDetailItem(item);
            setDetailItemIndex(index);
        }
    }

    const fav = async () => {
        if(!detailItem) return ;
        const isFavorites = 1 - detailItem.isFavorites ;
        await httpUtil.post('/biz/images/fav', {
            imageId : detailItem.imageId,
            isFavorites
        })

        const _data = {...detailItem, isFavorites}
        setDetailItem(_data);

        const tempList = ([] as Images[]).concat(list as Images[]);
        tempList[detailItemIndex] = _data;
        setList(tempList);
    }

    const del = async () => {
        if(!detailItem) return ;
        await httpUtil.post('/biz/images/del', {
            imageId : detailItem.imageId
        })
        setIsDelete(false);
        setDetailItem(undefined);
        currentPage == 1 ? getList() : setCurrentPage(1);
    }
    const download = () => {
        if(!detailItem) return ;
        httpUtil.download(Tools.completeUrl(detailItem.url), detailItem.projectName)
    }

    return (
        <div className="h-full overflow-y-auto" ref={listElement} style={{
            ...(listElement.current ? {  height : window.innerHeight - listElement.current.getBoundingClientRect().top } : {})
        }} onScroll={scrollContent}>
            <List tabs={[{
                title: 'All image',
                type : 0,
                callback: convertType
            },{
                title: 'Favorites',
                type : 1,
                callback: convertType
            }]} cols={7}>
                {
                    list && list.map((item,index) => (
                        <ListItem key={index} className="aspect-square" onClick={() => {
                            showDetail(index)
                        }}>
                            <img src={item.url} className="grow rounded-[10px] max-w-full max-h-full" />
                            <div className="absolute left-0 top-0 w-full h-full bg-[#000000]/[.6] hidden group-hover/list:block cursor-pointer rounded-[10px] p-[15px]">
                                <p className="text-[#FFFFFF] text-[16px]">{item.projectName}</p>
                            </div>
                        </ListItem>
                    ))
                }
            </List>
            {
                isLoading && <Loading />
            }
            {
                detailItem && 
                <div className="fixed z-[99] left-0 top-0 w-full h-full bg-[#000000]/[.5]">
                    <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[900px] h-[450px] bg-[#565656] rounded-[20px] flex">
                        <div className="flex grow py-[10px] items-center justify-center">
                            <img src={detailItem.url} className="max-w-[100%] max-h-[100%]" />
                        </div>
                        <div className="flex flex-col w-[300px] bg-[#000000] rounded-r-[20px] p-[20px] text-[#FFFFFF]">
                            <div className="grow flex flex-col gap-[15px]">
                                <p className="font-bold text-[20px] pr-[20px]">{detailItem.projectName}</p>
                                <div className="flex gap-[10px]">
                                    <img src={!detailItem.isFavorites ? "/icon/icon-like.png" : "/icon/icon-like-checked.png"} className="w-[25px] cursor-pointer" 
                                        onClick={() => fav()}/>
                                    <img src="/icon/icon-download-image.png" className="w-[25px] cursor-pointer" 
                                        onClick={() => download()}/>
                                    <img src="/icon/icon-delete-active.png" className="w-[25px] cursor-pointer" 
                                        onClick={() => setIsDelete(true)}/>
                                </div>
                                <div>
                                    <span className="border border-[#FFFFFF] rounded-[5px] px-[11px] py-[5px]">Aspect ratio: {detailItem.aspectRatio}</span>
                                </div>
                            </div>
                            <div className="pt-[15px] border-t-[1px] border-[#FFFFFF]">
                                <button className="p-[10px] rounded-[10px] bg-gradient-to-r from-[#14A5FA] to-[#10F28D]"
                                    onClick={() => gotoEditor(detailItem.projectId)}>Secondary editing</button>
                            </div>
                        </div>
                        <div className="absolute top-[18px] right-[18px]">
                            <img src="/icon/icon-close-white.png" className="w-[25px] cursor-pointer"
                                onClick={() => setDetailItem(undefined)}/>
                        </div>
                    </div>
                </div>
            }
            {
                isDelete && <DialogConfirm cancel={() => setIsDelete(false)} confirm={() => del()} />
            }
        </div>
    )
}