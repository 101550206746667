import React, { useEffect, useRef, useState } from "react";

type Drag = {
    isDraging : boolean
}

export default function Slider(props: { min?: number, max?: number, init: number, changeHandler: Function }){

    const { min = 0.1, max = 50, init, changeHandler} = props ;
    const [current, setCurrent] = useState<number>(init);
    const [proportion, setProportion] = useState<number>(0);
    const sliderElement = useRef<HTMLDivElement>(null);

    const [dragData, setDragData] = useState<Drag>(() => {
        return {
            isDraging : false
        }
    });

    useEffect(() => {
        setCurrent(init);
    }, [ init  ])

    useEffect(() => {
        setProportion(current / max * 100)
    }, [ current ])


    const disponsePosition = (targetX: number) => {
        if(!sliderElement.current?.parentElement) return ;
        const { left, width } = sliderElement.current?.parentElement?.getBoundingClientRect();
        let curr : number ;
        if(targetX <= left) curr = min;
        else if(targetX >= left + width) curr = max;
        else curr = (targetX - left) / width * max;

        setCurrent(curr);
        changeHandler(curr);
    }

    const clickHander = (e: any) => {
        disponsePosition((e as MouseEvent).clientX)
    }

    const mouseDown = () => {
        setDragData({...dragData, isDraging: true})
    }

    const mouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
        if(!dragData.isDraging) return ;
        disponsePosition(event.clientX)
    }

    const mouseUp = () => {
        setDragData({...dragData, isDraging: false})
    }

    return (
        <div className="relative grow h-[5px] py-[15px] flex items-center cursor-pointer" 
            onMouseDown={(e) => clickHander(e)}
            onMouseMove={mouseMove }
            onMouseUp={mouseUp}
            >
            <div className="relative w-full h-[5px] rounded-[10px]  bg-[#9C9C9C]">
                <div className="absolute top-0 left-0 bg-[#000000] h-full rounded-[10px]"
                    style={{
                        width: `${proportion}%`
                    }} ></div>
                <div className="absolute top-[-3px] w-[10px] ml-[-5px] h-[10px] border border-[#000000] rounded-[50%]  bg-[#FFFFFF] "
                    style={{
                        left: `${proportion}%`
                    }} ref={sliderElement}
                    onMouseDown={mouseDown}
                    ></div>
            </div>
        </div>
    )
}