import { usePermissionContext } from "../../context/permission";
import { useHttpUtil } from "context/httpContext";
import { useEffect, useState } from "react";
import DialogConfirm from "./dialogConfirm";
import Contact from "./contact";
import Button from "./button";

type User  = {
    name ?: string,
    email ?: string,
    credit ?: number,
    planId ?: string,
    planName ?: string,
    subscribeStatus ?: number,
    subscribeTime ?: string,
    startTime ?: string,
    expireTime ?: string
}

type Plan = {
    id: string,
    name: string,
    price: number,
    grade : number,
    prevName: string,
    items: string[],
    creditValue: number,
    creditCycle: number,
}

export default function Score(props: {children: any, className?: string}){
    const [user, setUser] = useState<User>();
    const updatorContext = usePermissionContext();
    const httpUtil = useHttpUtil();
    const [isInitPlan, setInitPlan] = useState<boolean>(false);
    const [planList, setPlanList] = useState<Plan[]>();
    const [isCancelPlan, setIsCancelPlan] = useState<boolean>(false);
    const [isCancelPlanSuc, setIsCancelPlanSuc] = useState<boolean>(false);
    const [isShowAccount, setIsShowAccount] = useState<boolean>(false);
    const [name, setName] = useState<string>();
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    useEffect(() => {
        isInitPlan && !(planList?.length) && initPlansList()
    },[ isInitPlan ])

    useEffect(() => {
        isShowAccount && !(planList?.length) && initPlansList()
    },[ isShowAccount ])

    
    useEffect(() => {
        updatorContext?.isShowUpgrade && setInitPlan(true)
    },[updatorContext?.isShowUpgrade]);

    useEffect(() => {
        syncUserInfo();
    },[updatorContext?.statsNumber]);

    const syncUserInfo = async () => {
        const res = await httpUtil.post('/biz/user/info',{});
        setName(res.data?.name);
        setUser(res.data)
    }

    const initPlansList = async () => {
        const res = await httpUtil.post('/biz/plan/list',{});
        const list = res.data ;
        const planList = [] ;
        for(let i = 0, len = list.length ;i < len ;i ++){
            const item = list[i];
            planList.push({
                id : item.planId ,
                name: item.name,
                price: item.price,
                grade : item.grade,
                startTime : item.startTime,
                expireTime : item.expireTime,
                subscribeTime: item.subscribeTime,
                creditValue: item.creditValue,
                creditCycle: item.creditCycle,
                items: JSON.parse(item.items),
                prevName : i > 0 ? list[i-1].name : ''
            })
        }
        setPlanList(planList);
    }

    const openOrClose = () => {
        updatorContext?.upgrade();
    }

    const upgradePlan = async (planId: string) => {
        const res = await httpUtil.post('/api/subscribe/' + planId, {});
        if(!res.errCode){
            location.href =  res.data.shopifyConfirmationUrl;
        }
    }

    const cancelPlan = () => {
        setIsCancelPlan(true);
    }

    const confirmCancel = async () => {
        setIsCancelPlan(false)
        setIsProcessing(true);
        const resData = await httpUtil.post('/api/cancel/subscribe');
        if(!resData.errCode){
            let max = 6, curr = 0 ;
            let timer : NodeJS.Timeout ;
            timer = setInterval(async () => {
                const res = await httpUtil.post('/biz/plan/order/cancel/status');
                if(!res.errCode || curr ++ >= max){
                    clearInterval(timer);
                    setIsProcessing(false);
                }
                if(!res.errCode){
                    setIsCancelPlanSuc(true);
                }
            }, 500)
            return ;
        }
        setIsProcessing(false);
    }
    
    const confirmCancelSuc = () => {
        updatorContext?.updator();
        setIsCancelPlanSuc(false);
      }

    const showAccount = () => {
        setIsShowAccount(true);
    }

    const hiddenAccount = () => {
        setIsShowAccount(false);
    }

    const {children, className = ''} = props ;
    return (
        <>
            <div className={className}>
                <div className="flex gap-[10px]">
                    {children}
                    <div className="flex justify-center">
                        <span className="flex items-center gap-[6px] px-[14px] border border-[#10F28D] border-r-0 rounded-l-[10px] text-[#14A5FA]"><img src="/icon/icon-score.png" className="w-[20px] h-[20px]"/> <span>{user?.credit}</span></span>
                        <button className="flex items-center rounded-r-[10px] bg-gradient-to-r from-[#14A5FA] to-[#10F28D] text-[#FFFFFF] px-[10px] text-[14px]"
                            onClick={openOrClose}>Upgrade</button>
                    </div>
                    <div className="group relative flex w-[35px] h-[35px] items-center justify-center rounded-full bg-[#e4e5e7] cursor-pointer">
                        <span>{name?.substring(0,2).toLocaleUpperCase()}</span>

                        <div className="absolute right-0 top-[30px] w-[150px] pt-[10px] hidden group-hover:block z-[10000]">
                            <div className="w-full rounded-[5px] border border-[#D9D9D9] flex flex-col bg-[#FFFFFF]">
                                <div className="p-[10px] flex gap-[10px] items-center">
                                    <span className="flex items-center justify-center w-[35px] h-[35px] rounded-full bg-[#e4e5e7]">{name?.substring(0,2).toLocaleUpperCase()}</span>
                                    <div className="flex flex-col">
                                        <span className="text-[#000000] font-semibold text-[14px]">{name}</span>
                                        <span>{user?.planName}</span>
                                    </div>
                                </div>
                                <div className="p-[5px] pb-0 border-t">
                                    <div className="hover:bg-[#D9F2FF] py-[6px] pl-[10px] rounded-[5px] cursor-pointer flex gap-[8px] items-center"
                                         onClick={showAccount}>
                                        <img src="/icon/icon-account.png" className="w-[15px] h-[15px]"/>
                                        <span>My Account</span>
                                    </div>
                                </div>
                                <div className="p-[5px] pt-0">
                                    <div className="hover:bg-[#D9F2FF] py-[6px] pl-[10px] rounded-[5px] cursor-pointer flex gap-[8px] items-center"
                                         onClick={() => window.open('https://www.mockuplabs.ai/pages/help/')}>
                                        <img src="/icon/icon-account-help.png" className="w-[15px] h-[15px]"/>
                                        <span>Help</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                updatorContext?.isShowUpgrade &&
                <div className="fixed left-0 top-0 w-full h-full bg-[#000000]/[0.5] z-[10003]">
                    <div className="absolute w-[850px] left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] px-[90px] py-[55px] bg-[#FFFFFF] rounded-[20px]">
                        <img src="/icon/icon-close.png" className="absolute right-[30px] top-[30px] w-[30px] cursor-pointer" 
                            onClick={openOrClose}/>

                        <h4 className="text-[24px] font-bold text-center">Unlock magic with Mockuplabs advanced program</h4>
                        <p className="text-center text-[16px] font-semibold mt-[20px]">Take advantage of Mockuplabs product mockup generator and our suite of ecommerce and editing AI tools to get more done quickly and efficiently.</p>
                        <div className="flex gap-[30px] mt-[40px]">
                            {
                                planList?.map((item : Plan) => (
                                    <div className="basis-1/2 border border-[#D9D9D9] rounded-[10px] pt-[30px] pb-[20px] px-[20px]"
                                        style={{
                                            borderColor : user?.planId == item.id ? '#14A5FA' : '#D9D9D9',
                                            borderWidth : user?.planId == item.id ? '2px' : '1px'
                                        }}
                                        key={item.id}>
                                        <p className="text-[24px] font-bold text-center">{item.name}</p>
                                        <p className="text-[42px] font-bold text-center mt-[30px]">${item.price}/mo</p>
                                        <div className="flex flex-col  h-[220px] pl-[40px] mt-[30px]">
                                            <span className="text-[14px] font-semibold">{item.prevName ? (`Everything in ${item.prevName} +`) : 'Try all features'}</span>
                                            {
                                                item.items.map((str: string, index: number) => (
                                                    <div className="flex items-center gap-[10px] mt-[10px]" key={index}>
                                                        <img src="/icon/icon-plan-item.png" className="w-[20px]" /><span className="text-[14px] font-semibold">{str}</span>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        {
                                            item.grade == 1 &&
                                            <div className="border border-[#000000] rounded-[10px] w-full py-[15px] text-[16px] font-semibold text-center">{item.id == user?.planId ? 'Current plan' : 'Default plan'}</div>
                                        }
                                        {
                                            item.grade > 1 && 
                                            (
                                                item.id != user?.planId ? 
                                                <div className="bg-[#14A5FA] text-[#FFFFFF] rounded-[10px] w-full py-[15px] text-[16px] font-semibold text-center cursor-pointer"
                                                    onClick={() => upgradePlan(item.id)}>Upgrade plan</div>
                                                :
                                                item.id == user.planId &&
                                                (
                                                    user.subscribeStatus == 1 ? 
                                                        <div className="bg-[#000000] text-[#FFFFFF] rounded-[10px] w-full py-[15px] text-[16px] font-semibold text-center cursor-pointer"
                                                            onClick={() => cancelPlan()}>Unsubscribe</div>
                                                    :
                                                        <div className="bg-[#14A5FA] text-[#FFFFFF] rounded-[10px] w-full py-[15px] text-[16px] font-semibold text-center cursor-pointer"
                                                            onClick={() => upgradePlan(item.id)}>Renew now</div>
                                                )
                                            )
                                        }
                                        
                                    </div>
                                ))
                            }
                        </div>
                        <div className="absolute right-[90px] bottom-[25px] flex items-center gap-[5px]">
                            <img src="/icon/icon-help.png" className="w-[20px]" />
                            <span className="text-[#BFBFBF] text-[14px] cursor-pointer"
                                onClick={() => window.open('/credit/rule')}>Subscription credits rules</span>
                        </div>
                    </div>
                </div>
            }

            {
                isCancelPlan &&
                <DialogConfirm 
                    width={600}
                    title="Are you sure you want to cancel your subscription?"
                    content={[
                        'If you cancel your subscription, auto-renewal will be automatically turned off and no further charge will be made for the next month.',
                        'You can continue to use the remaining credits until the end of the subscription period, and the credits will be cleared at the end of the subscription period and automatically converted to the free plan.',
                        'If you want to check the expiration date of your subscription, you can do so by clicking on your account profile image and then clicking on "My Account".'
                    ]}
                    cancel={() => setIsCancelPlan(false)} confirm={() => confirmCancel()}></DialogConfirm>
            }
            
            {
              isCancelPlanSuc &&
                <DialogConfirm
                  width={600}
                  title="You have successfully unsubscribed !"
                  content={[
                      'Subscription details can be viewed by clicking on your account avatar and then clicking on “My Account”.'
                  ]}
                  confirm={confirmCancelSuc}></DialogConfirm>
            }
            {
                isShowAccount &&  <Account user={user as User} planList={planList as Plan[]} isProcessing={isProcessing}
                    cancel={hiddenAccount} cancelPlan={cancelPlan} upgradePlan={upgradePlan}></Account>
            }
        </>
    )
}

// 账号信息
function Account(props: { user: User, planList: Plan[], isProcessing: boolean, cancel: Function, cancelPlan: Function, upgradePlan: Function}){
    const {user, planList, isProcessing, cancel, cancelPlan, upgradePlan} = props;
    const updatorContext = usePermissionContext();
    const [isContact, setIsContact] = useState<boolean>(false);
    const [currentPlan, setCurrentPlan] = useState<Plan>();

    useEffect(() => {
        if(planList){
            let plan = planList[0];
            for(let i = 0, len = planList && planList.length || 0 ;i < len ;i ++){
                const item = planList[i];
                if(item.id === user.planId){
                    plan = item ;
                }
            }
            setCurrentPlan(plan);
        }
    }, [ planList ])

    const ctime = (time: string | undefined) => {
        return time && time.substring(0,10) || ''
    }

    return (
        <>
            <div className="fixed w-full h-full left-0 top-0 bg-[#000000]/[0.5] z-[10002]">
                <div className="absolute w-[780px] left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] p-[30px] bg-[#ffffff] rounded-[15px]">
                    <img src="/icon/icon-close.png" className="absolute right-[30px] top-[30px] w-[30px] cursor-pointer" 
                        onClick={() => {cancel()}}/>

                    <div className="flex items-center gap-[10px] py-[10px] px-[30px]">
                        <span className="flex items-center justify-center w-[50px] h-[50px] rounded-full bg-[#e4e5e7] text-[16px]">{user.name?.substring(0,2).toLocaleUpperCase()}</span>
                        <span className="text-[20px] font-semibold">{user.name}</span>
                    </div>
                    <div className="flex items-center mt-[3px] pl-[30px] pr-[10px] py-[10px]">
                        <div className="flex items-center gap-[10px]">
                            <img src="/icon/icon-email.png" className="w-[25px]" />
                            <span className="text-[16px] font-semibold">Email address</span>
                        </div>
                        <span className="grow text-right font-medium text-[14px]">{user.email}</span>
                    </div>
                    <div className=" mt-[10px] pl-[30px] py-[10px]">
                        <div className="flex items-center gap-[10px]">
                            <img src="/icon/icon-plans.png" className="w-[25px]" />
                            <span className="text-[16px] font-semibold">Plans</span>
                        </div>
                    </div>
                    <div className="pl-[60px] pr-[30px]">
                        <div className="py-[14px]">
                            <span className="text-[16px] font-semibold">Subscription</span>
                        </div>
                        <div className="py-[14px] flex items-center">
                            {
                                currentPlan && currentPlan.price > 0 ? 
                                <>
                                    <span className="text-[14px] w-[50%]">{currentPlan.name}</span>
                                    <span className="grow text-[14px]">$ {currentPlan.price}/mo</span>
                                </>
                                : <span className="text-[14px] grow">{currentPlan && currentPlan.name || 'Loading'}</span>
                            }
                            <Button txt="Change" clickEvent={() => updatorContext?.upgrade() }></Button>
                        </div>
                        <div className="py-[14px] flex items-center">
                            <span className="text-[14px] w-[50%]">Subscription Date</span>
                            <span className="text-[14px] text-center">{ctime(user.subscribeTime)}</span>
                        </div>
                        {
                            currentPlan && currentPlan.price > 0 && 
                            <div className="py-[14px] flex items-center">
                                <div className="flex flex-col gap-[5px] w-[50%]">
                                    <span className="text-[14px]">Expiry Date</span>
                                    <span className="text-[14px] text-[#787878]">Auto-renewal is enabled.</span>
                                </div>
                                <span className="grow text-[14px]">{ctime(user.expireTime)}</span>
                                {
                                    user.subscribeStatus == 1 ?
                                        <Button txt="Abolish" clickEvent={cancelPlan} isLoading={isProcessing}></Button>
                                    :
                                        <Button txt="Renew now" isLoading={isProcessing} clickEvent={() => {upgradePlan(currentPlan.id)}}></Button>
                                }  
                                
                            </div>
                        }

                        <div className="py-[14px]">
                            <span className="text-[16px] font-semibold">Credits</span>
                        </div>
                        <div className="py-[14px] flex items-center">
                            <div className="flex flex-col gap-[5px] grow">
                                <span className="text-[14px]">{currentPlan && currentPlan.creditValue || 0} Credits</span>
                                <span className="text-[14px] text-[#787878]">Automatically refreshes when it expires each {currentPlan && currentPlan.creditCycle == 1 ? 'week' : 'month'}.</span>
                            </div>
                            <Button txt="Change" clickEvent={() => updatorContext?.upgrade() }></Button>
                        </div>
                        <div className="py-[14px] flex items-center">
                            <div className="flex flex-col gap-[5px] grow">
                                <span className="text-[14px] ">0 Free Access Credits</span>
                                <span className="text-[14px] text-[#787878]">Credits earned and purchased for free do not expire.</span>
                            </div>
                            <Button txt="Get credits" clickEvent={() => window.open('/credit/free')}></Button>
                        </div>
                        <div className="py-[14px] flex items-center">
                            <span className="text-[14px] grow">Get More Credits</span>
                            <Button txt="Contact us" clickEvent={() => setIsContact(true)}></Button>
                        </div>
                    </div>
                </div>
            </div>
            {
                isContact && <Contact cancel={() => setIsContact(false)} />
            }
        </>
    )
}