
export default function Bubble(props: { title: string, desc?: string, className: string}){
    const {title, desc = '', className} = props ;

    return (
        <div
            style={{ width: desc ? '280px' : 'auto'}} 
            className={`absolute left-[50%] translate-y-[25px] translate-x-[-50%] z-[999999] px-[22px] py-[10px] bg-[#000000] hidden text-[#ffffff] rounded-[10px] ${className}`}>
            <div className="absolute left-[50%] top-0 translate-y-[-5px] translate-x-[-50%] w-[20px] h-[20px] rotate-45 bg-[#000000]"></div>
            <p className="font-bold text-[14px]">{title}</p>
            {
                !!desc && <p>{desc}</p>
            }
        </div>
    )
}  