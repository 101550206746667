import { useHttpUtil } from "context/httpContext";
import { useRef, useState } from "react";


export default function Contact(props: {cancel: Function}){
    const {cancel } = props ;
    const httpUtil = useHttpUtil();
    const [feedback, setFeedback] = useState<Feedback>({ });
    const inputElements = useRef<(HTMLInputElement|HTMLTextAreaElement|null)[]>([]);

    const submit = async () => {
        console.info(feedback)
        if(!feedback.fullname){
            return inputElements.current[0]?.focus();
        }
        if(!feedback.email){
            return inputElements.current[1]?.focus();
        }
        if(!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/ig.test(feedback.email)){
            return inputElements.current[1]?.focus();
        }
        if(!feedback.message){
            return inputElements.current[2]?.focus();
        }
        const resData = await httpUtil.post('/biz/feedback/submit', feedback);
        if(!resData.errCode) cancel && cancel() ;
    }
    return (
        <div className="fixed w-full h-full left-0 top-0 bg-[#000000]/[0.5] z-[10002]">
            <div className="absolute w-[780px] left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] py-[30px] px-[60px] bg-[#ffffff] rounded-[15px] text-center">
                <img src="/icon/icon-close.png" className="absolute right-[30px] top-[30px] w-[30px] cursor-pointer" 
                        onClick={() => {cancel()}}/>
                <p className="font-bold text-[24px] ">Contact Customer Care</p>
                <p className="text-[14px] font-bold mt-[30px]">We are more than happy to listen to any suggestions/bugs/feature requests/credits you may have. Please leave a message below and we will get back to you as soon as possible and do our best to fulfill your request.</p>

                <div className="mt-[30px]">
                    <div className="flex flex-col gap-[10px]">
                        <span className="text-left font-bold text-[16px]">Full Name *</span>
                        <input type="text" className="w-[100%] py-[10px] px-[10px] border rounded-[5px] border-[#BFBFBF]"
                            maxLength={50} 
                            ref={(el) => inputElements.current[0] = el}
                            onChange={(e) => setFeedback({...feedback, fullname: e.target.value})}/>
                    </div>
                    <div className="flex flex-col gap-[10px] mt-[20px]">
                        <span className="text-left font-bold text-[16px]">Email *</span>
                        <input type="email" className="w-[100%] py-[10px] px-[10px] border rounded-[5px] border-[#BFBFBF]"
                            maxLength={50}  
                            ref={(el) => inputElements.current[1] = el}
                            onChange={(e) => setFeedback({...feedback, email: e.target.value})}/>
                    </div>
                    <div className="flex flex-col gap-[10px] mt-[20px]">
                        <span className="text-left font-bold text-[16px]">Message *</span>
                        <textarea className="w-[100%] h-[150px] py-[10px] px-[10px] border rounded-[5px] border-[#BFBFBF]"
                            maxLength={1000} 
                            ref={(el) => inputElements.current[2] = el}
                            onChange={(e) => setFeedback({...feedback, message: e.target.value})}></textarea>
                    </div>
                </div>

                <button className="rounded-[10px] bg-[#14A5FA] text-[#FFFFFF] text-[16px] px-[32px] py-[10px] mt-[30px]"
                    onClick={() => submit()}>Submit</button>
            </div>
        </div>
    )
}

type Feedback = {
    fullname ?: string,
    email ?: string,
    message ?: string
}