import HttpLayout from "context/httpContext";
import LoadingLayout from "context/loadingLayout";
import Updator from "../../context/permission";

export default function Global(props : { children : any}){
    return (
        <>
            <Updator>
                <HttpLayout>
                    <LoadingLayout>
                        {props.children}
                    </LoadingLayout>
                </HttpLayout>
            </Updator>
        </>
    )
}