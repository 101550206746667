
const Tools = {
    completeUrl : (url: string) => {
        if(!/^http/ig.test(url)){
            const port = +location.port;
            url = `${location.protocol}//${location.hostname}${port != 80 && port != 443 ? (':' + port) : ''}` + url
        }
        return url ;
    }
}

export default Tools ;