import 'react-app-polyfill/ie11'; 
import 'react-app-polyfill/stable';
import KeenBowImageEdit from "../../../libs/editor/bundle";
import {useEffect, useRef, useState} from "react";
import CanvasKitInit from "canvaskit-wasm";
import CanvasKitWasm from "canvaskit-wasm/bin/canvaskit.wasm";
import { mockupUrl, useHttpContext } from 'context/httpContext';
import { useLoadingContext } from 'context/loadingLayout';
import { LayerItem } from './toolskit';
import { ProjectInfo } from './detail';

export default function MockupEditor(props: {
    action: Action | undefined,
    project : ProjectInfo,
    onDataListener : (params: EventCallbackParams) => void
}) {
    const loadingContext = useLoadingContext();
    const parentElement = useRef<HTMLDivElement>(null);
    const [size, setSize] = useState<CanvasSize>();
    const [mockupApi, setMockupApi] = useState<MockupApiInterface>();
    const httpContent = useHttpContext();
    const [ isSetServer, setIsSetServer ] = useState<boolean>(false);
    const { action, onDataListener, project } = props ;

    const divId = 'mockupEditorScope';

    useEffect(() => {
        if(parentElement.current){
            const width = parentElement.current.clientWidth,
                height = parentElement.current.clientHeight;
            //初始化画板
            new KeenBowImageEdit();
            setSize({width,height})
            // 实例后，初始化画布
            KeenBowImageEdit.instance.initCavas(CanvasKitInit,CanvasKitWasm, divId,width * 33,height * 33,function (code: any,result: any) {
                // code :0 成功 
                if(!code){
                    setMockupApi(KeenBowImageEdit.instance);
                    loadingContext?.setIsLoadingLayout(false);
                }
            })
        }
    },[])

    useEffect(() => {
        // 触发设置地址
        setServerAddress();
    },[ project.baseUrl ])

    useEffect(() => {
        // 服务地址已正确设置且有项目数据，则加载数据
        if(isSetServer && project.content){
            const urls = project.urls ;
            let paramsUrls = '{}';
            if(urls){
                const rurls : any = {} ;
                Object.keys(urls).forEach(key => {
                    rurls[key] = urls[key] + '?' + project.projectId ;
                });
                paramsUrls = JSON.stringify(rurls);
            }
            mockupApi?.loadProjectData(project.content, paramsUrls, (code: number) => {
            });
        }
    },[ project.content, isSetServer ])

    useEffect(() => {
        if(mockupApi){
            // 注册事件
            mockupApi.registerEventsCallBack((type: any, data: any) => {
                // console.info('event callback', type, {data})
                switch(type){
                    case 1: // 移动结束
                    case 2: // 旋转结束
                    case 3: // 缩放结束
                    // 删除图像 / 添加图像
                    case 5: case 6:
                    // 向量图与底图相互转换
                    case 16: 
                    case 20: case 21: // 撤销/恢复
                        // 需延迟一点触发,否则会出现当前回调被覆盖不会被触发
                        setTimeout(() => {
                            onDataListener({
                                type: EventCallbackParamsType.LAYER_MODIFY_CALLBACK,
                            })
                        }, 50);
                }

                switch(type){
                    case 0: // 对象选择
                    case 1: // 移动结束
                    case 6: // 添加图像后会自动选择， 不会触发0
                        // console.info('image', mockupApi.getSelectImageData())
                        onDataListener({
                            type: EventCallbackParamsType.OBJECT_SELECT,
                            // data: KeenBowImageEdit.instance.getSelectImageData()
                            data : data as MockupObject
                        })

                        // KeenBowImageEdit.instance.modifyVectorImageParameter(0, 0.8, 0.2, 0.8)
                        break;
                    case 4: // 取消选择
                    case 5 :// 删除时取消选中
                        onDataListener({
                            type: EventCallbackParamsType.OBJECT_CANCEL_SELECT,
                        })
                        break; 
                    case 19: // 画板缩放
                        onDataListener({
                            type: EventCallbackParamsType.PANEL_SCALE_CALLBACK,
                            data: `${Math.ceil(data * 100)}`
                        })
                        break;
                }

                switch(type){
                    // 移动结束事件 / 旋转结束事件 / 缩放结束事件
                    case 1: case 2: case 3: 
                    // 删除图像 / 添加图像
                    case 5: case 6:
                    // 向量图与底图相互转换
                    case 16: 
                    case 20: case 21: // 撤销/恢复
                    case 22: // 图层隐藏
                        onDataListener({
                            type: EventCallbackParamsType.EDITOR_CHANGE,
                            data : `${mockupApi.getUndoState() ? 1 : 0}:${mockupApi.getRndoState() ? 1 : 0}`
                        })
                }

                if(type == 23){ // base64变化 
                    onDataListener({
                        type: EventCallbackParamsType.ITEM_BASE64_CHANGE,
                        data : data 
                    })
                }else if(type == 25){
                    onDataListener({
                        type: EventCallbackParamsType.MOUSE_CHANGE_CALLBACK,
                        data : data 
                    })
                }
            })

            // 初始化相关数据
            setServerAddress();
        }
    },[mockupApi])

    // 设置地址
    const setServerAddress = () => {
        if(project.baseUrl && !isSetServer && mockupApi){
            // console.info('setting server address', project.baseUrl);
            mockupApi.setServerAddress(mockupUrl.depthUrl, mockupUrl.processImageUrl, mockupUrl.inpaintUrl, mockupUrl.aiSegmentationUrl, project.baseUrl);
            setIsSetServer(true);
        }
    }

    useEffect(() => {
        if(!action) return ;
        switch(action.name){
            case ActionName.CHANGE_MOUSE_MODE: // 切换鼠标模式
                mockupApi?.switchMouseType(action.params ? action.params : 0);
                break;
            case ActionName.INIT_EDITOR_DATA: // 初始化编辑器数据
                // console.info('loading data',action.params)
                setTimeout(() => {
                    mockupApi?.loadProjectData(action.params, (code: number) => {
                        // console.info('load data', code)
                    });
                },30);
                break;
            case ActionName.LOADING_IMAGE: // 加载图片
                mockupApi?.addImage(2, action.params, (code: number, result: string) => {
                    // if(code){
                    //     alert(result);
                    // }
                })
                break;
            case ActionName.REPLACE_IMAGE: // 替换图片
                mockupApi?.updateSelectImage(action.params, (code: number, result: string) => {
                    // console.info('replace', code)
                })
                break;
            case ActionName.MOCKUP: // 转mockup
                // console.info('mockup start ')
                const mockupUUID = action.params ;
                mockupApi?.transformImageTypeInSelect((code: number, result: any) => {
                    // console.info('mockup end',code,result)
                    if(!code){
                        httpContent?.response(0, 'Successfully converted to mockup')
                        onDataListener({
                            type: EventCallbackParamsType.OBJECT_SELECT,
                            data: result
                        })
                    }else if(code === -1){
                        httpContent?.response(100, 'Failed to convert to mockup')
                    }else if(code == 1){ // 正在转换中
                        onDataListener({
                            type: EventCallbackParamsType.MOCKUP_COVERTING,
                            data: mockupUUID
                        })
                    }
                    if(code < 1){
                        onDataListener({
                            type: EventCallbackParamsType.ACTION_FINISH,
                            data: mockupUUID
                        })
                    }
                });
                break;
            case ActionName.VECTOR: // 转向量图
                mockupApi?.merageMockUpImagesToSingleImage((code: number, result: any) => {
                    if(!code){
                        httpContent?.response(0, 'Rasterization successful')
                        onDataListener({
                            type: EventCallbackParamsType.OBJECT_SELECT,
                            data: result
                        })
                    }
                });
                break;
            case ActionName.VECTOR_ON_MOCKUP_SETTING: // 底图上的向量图属性设置
                const fineTuning = action.params as FineTuningObject ;
                mockupApi?.modifyVectorImageParameter(fineTuning.saturation * -1, fineTuning.opacity, fineTuning.contrast, fineTuning.brightness);
                break;
            case ActionName.DOWNLOAD: // 下载
            case ActionName.VECTOR_REMOVE_BACKGROUD: // 去除背景
                mockupApi?.saveSelectImageToBase64((code: number, result: string) => {
                    if(!code){
                        onDataListener({
                            type: action.name == ActionName.DOWNLOAD ? EventCallbackParamsType.DOWNLOAD_CALLBACK :  EventCallbackParamsType.VECTOR_REMOVE_BACKGROUD_CALLBACK,
                            data: result
                        })   
                    }
                })

                break;
            case ActionName.GET_EDITOR_DATA: // 获取编辑器数据
                mockupApi?.exportProjectData((code: number, data: string) => {
                    // console.info('get project data', code)
                    let resultData = undefined ;
                    if(!code){
                        mockupApi?.captureThrumbailImage((code: number, result: string) => {
                            resultData = {
                                data: data,
                                previewImage: !code ? result : null
                            }
                        });
                    }else if(code == -1){ // 无数据
                        // onDataListener({
                        //     type: EventCallbackParamsType.GET_EDITOR_DATA_CALLBACK,
                        //     data: ""
                        // })  
                        resultData = {
                            data: '',
                            previewImage: null
                        }
                    }
                    if(resultData){
                        onDataListener({
                            type: EventCallbackParamsType.GET_EDITOR_DATA_CALLBACK,
                            data: resultData
                        })   
                    }
                });
                break;
            // =============== 向量图画笔(AI消除) ==============
            case ActionName.VECTOR_AI_ERASE_MAN_START: // 开启手动画笔
                mockupApi?.startManAIErase((code: number) => {
                    // console.info('startManAIErase code', code)
                });
                // 自动返回画笔大小
                onDataListener({
                    type: EventCallbackParamsType.GET_PEN_SIZE_CALLBACK,
                    data: mockupApi?.getDrawPenWidth()
                })  
                break;
            case ActionName.VECTOR_AI_ERASE_CIRCLE_START: // 开启圈选画笔
                mockupApi?.startCicleAIErase();
                break;
            case ActionName.VECTOR_AI_ERASE_CLEAR: // 开始AI消除
                mockupApi?.startAIErase((code: number) => {
                    if(!code){
                        onDataListener({
                            type: EventCallbackParamsType.PEN_CONFIRM_CALLBACK,
                        })  
                    }
                });
                break;
            case ActionName.VECTOR_AI_ERASE_CLOSE: // 关闭画笔
                mockupApi?.stopEraseDraw();
                break;
            case ActionName.SETTING_PEN_SIZE: // 设置画笔大小
                mockupApi?.setDrawPenWidth(action.params);
                break;
            // ================ 底图画笔 ==========
            case ActionName.MOCKUP_AREA_START: // 开启区域画笔
                mockupApi?.startAreaErase((code: number) => {
                    // console.info('code', code)
                });
                break;
            case ActionName.MOCKUP_PEN_SWITCH: // 切换区域|圈选画笔
                mockupApi?.switchAreaEraseType(+(action.params || 1));
                break;
            case ActionName.MOCKUP_CONFIRM: // 确认区域画笔
                mockupApi?.processAreaErase((code: number) => {
                    // console.info('code', code)
                    onDataListener({
                        type: EventCallbackParamsType.PEN_CONFIRM_CALLBACK,
                    })  
                });
                break;
            // ================ 底图智能画笔 ==========
            case ActionName.MOCKUP_AI_AREA_START: // 开启区域画笔
                // console.info('start startAIAreaErase')
                mockupApi?.startAlAreaErase((code: number) => {
                    // console.info('startAIAreaErase code', code)
                });
                break;
            case ActionName.MOCKUP_AI_PEN_SWITCH: // 切换区域|圈选画笔
                mockupApi?.switchAIAreaEraseType(+(action.params || 1));
                break;
            case ActionName.MOCKUP_AI_ANALYSIS: // 开始智能分析
                // console.info('start analysisAIAreaErase')
                mockupApi?.analysisAIAreaErase((code: number) => {
                    // console.info('analysis', code)
                });
                break;
            case ActionName.MOCKUP_AI_CONFIRM: // 确认区域画笔
                mockupApi?.processAIAreaErase((code: number) => {
                    onDataListener({
                        type: EventCallbackParamsType.PEN_CONFIRM_CALLBACK,
                    })  
                });
                break;
            // ================  图层 ================
            case ActionName.TOOLSKIT_REDO: // 上一步
                mockupApi?.redo();
                onDataListener({
                    type: EventCallbackParamsType.EDITOR_CHANGE,
                    data : `${mockupApi?.getUndoState() ? 1 : 0}:${mockupApi?.getRndoState() ? 1 : 0}`
                })
                break;
            case ActionName.TOOLSKIT_UNDO: // 下一步
                mockupApi?.undo();
                onDataListener({
                    type: EventCallbackParamsType.EDITOR_CHANGE,
                    data : `${mockupApi?.getUndoState() ? 1 : 0}:${mockupApi?.getRndoState() ? 1 : 0}`
                })
                break;
            case ActionName.GET_LAYER_LIST:  // 获取图层列表
                const listCallback = (list: any) : LayerItem[] => {
                    const layerList : LayerItem[] = [] ;
                    for(let i = 0, len = list.length;i < len ;i ++){
                        const item = list[i];
                        const layerItem : LayerItem = {
                            uuid: item.uuid,
                            parentUuid: item.mapUUId || 0,
                            type: item.type,
                            isExpand : false,
                            isHide : !!mockupApi?.getItemDataHiddenStateByUuid(item.uuid),
                            isUnlocked : mockupApi?.getItemDataLockStateByUuid(item.uuid)
                        }
                        if(layerItem.type != 2){
                            const childrenList = listCallback(mockupApi?.getSecondaryItemsDatabyUUid(item.uuid));
                            if(childrenList && childrenList.length){
                                layerItem.children = childrenList ;
                            }
                        }
                        layerList[i] = layerItem ;
                    }
                    return layerList ;
                }
                onDataListener({
                    type: EventCallbackParamsType.GET_LAYER_LIST_CALLBACK,
                    list : listCallback(mockupApi?.getFirstItemsData() || [])
                })  
                break;
            case ActionName.LAYER_LOCKED_STATE: // 图层锁定/解锁
                let layerItem = action.params as LayerItem ;
                mockupApi?.modifyItemDataLockStateByUuid(layerItem.uuid, layerItem.isUnlocked || false);
                break;
            case ActionName.LAYER_HIDE_STATE: // 图层隐藏/显示
                let lockedLayerItem = action.params as LayerItem ;
                mockupApi?.modifyItemDataHiddenStateByUuid(lockedLayerItem.uuid, lockedLayerItem.isHide || false);
                mockupApi?.updateCanvas();
                break;
            case ActionName.LAYER_POSITION : // 图层定位
                mockupApi?.positioningToImageByUuid(action.params as string);
                break;
            case ActionName.LAYER_SORT: // 图层排序
                const uuids = action.params && (action.params as string).split(',') || [];
                if(uuids.length == 2){
                    mockupApi?.swapFirstItemDataByUuid(uuids[0],uuids[1])
                }
                break;
            // ============ 智能换色 =============
            case ActionName.SMART_COLOR_START:  // 开启智能换能
                mockupApi?.startAIColorErase((code: number) => {
                    if(code == 0){
                        onDataListener({
                            type: EventCallbackParamsType.SMART_COLOR_AI_READY
                        }) 
                    }else if(code == 1000 || code == 1001){
                        onDataListener({
                            type: EventCallbackParamsType.SMART_COLOR_CHOICE_STATUS,
                            data: `1`
                        })  
                    }
                });
                break;
            case ActionName.SMART_COLOR_SETTING: // 设置属性
                const param = action.params as SmartColorFineTuningObject ;
                mockupApi?.modifySelectAreaImageParameter(param?.color || '', param.saturation * -1, param.opacity, param.contrast, param.brightness)
                break;
            case ActionName.SMART_COLOR_PROCESS: // 处理智能换色结果并刷新画板
                console.info('processAIColorErase start')
                mockupApi?.processAIColorErase((code: number) => {
                    console.info('processAIColorErase result code', code)
                    mockupApi?.stopEraseDraw();
                })
                break;
            
            case ActionName.SMART_COLOR_MAN_START:          // 开启手动涂抹画板且开启换色功
                mockupApi?.startManAIErase((code: number) => {
                });
                onDataListener({
                    type: EventCallbackParamsType.GET_PEN_SIZE_CALLBACK,
                    data: mockupApi?.getDrawPenWidth()
                }) 
                break;
            case ActionName.SMART_COLOR_MAN_SWITCH_TYPE:     // 手动涂抹画笔切换(针对向里图)
                console.info('SMART_COLOR_MAN_SWITCH_TYPE', action.params)
                mockupApi?.switchManColorDooleType(+(action.params || 1));
                break;
            case ActionName.SMART_COLOR_MAN_UPDATE:          // 修改手动涂抹颜色值(针对向量图)
                console.info('modifyManColorDoole', action.params)
                mockupApi?.modifyManColorDoole(action.params as string);
                break;
            case ActionName.SMART_COLOR_MAN_PROCESS:        // 生效手动涂抹效果并刷新画板
                console.info('SMART_COLOR_MAN_PROCESS')
                mockupApi?.processManColorDooleErase((code: number) => {
                    console.info('SMART_COLOR_MAN_PROCESS code', code)
                    mockupApi?.stopEraseDraw();
                });
                break;
            
            
        }
    }, [action])
  return (
    <div className="w-full h-full overflow-hidden" ref={parentElement}>
      <div id={divId} style={{width:`${size?.width}px`,height:`${size?.height}px`,display:'block'}} >
        
      </div>
    </div>
  );
}


type CanvasSize = {
    width : number,
    height: number
}

type MockupApiInterface = {
    updateCanvas: () => void, // 手动刷新画布
    initCavas: Function, // 初始化画布
    // 添加图片(type, imageUrl) : type: 1、背景(深度)  2、前景(向量图)
    addImage: (type:number, imageUrl: any, callback: (code: number, result: any) => void) => void,  
    // 转mockup
    transformImageTypeInSelect: (callback: (code: number, result: any) => void) => void,
    getSelectImageData : () => any , // 获取选中的图片
    /**
     *  Selected:0,//选中了事件
        Moved:1,//移动结束事件
        Rotated:2,//旋转结束事件
        Scaled:3,//缩放结束事件
        QuitSelected:4,//取消选中事件
        Deleted: 5,//删除事件
        Added:6,//添加事件
        Updated:7,//更新事件
        StartMove:8,//準備移動中
        Moving:9,//正在移动中
        None:10,
        StartScale:11,//开始缩放
        StartRotate:12,//开始旋转
        Scaling:13,//缩放中
        Rotating:14,//旋转中
        EraseDrawing:15, //擦除面板绘制中
        SwitchType:16,//改变类型
        SaveImage:17,//保存图像
        Inited:18,//画板初始化时间
        CanvasScaling:19,//画板缩放
        Undostate:20,//撤销时间
        Redostate:21,//回退事件
        Hidden:22,// 图层隐藏事件
        upload:23,//base64有变化时的通知,
        QuitDrawed: 24,//退出绘制
        changeMouseType:25//鼠标模式类型改变事件，0代表指针模式，1代表拖动模式
     */
    registerEventsCallBack: (callback: (type: number, data: any) => void) => void , // 注册事件(type,data)
    saveSelectImageToPngFile: Function, // 下载图片(fileName)
    saveSelectImageToBase64: (callback: (code: number, result: string) => void) => void ,  // 保存当前选中的图像

    // modifyVectorImageAlpha: Function, // 设置向量图透明度(废弃)

    // 修改向量图的参数(饱和度;值的范围-1-1, 不透明度;值的范围0-1, 对比度:值的范围-1- 1, 亮度:值的范围-1-1)
    modifyVectorImageParameter: (saturation: number, opacity: number, contrast: number, brightness: number) => void, 
    // 设置服务地址(深度图服务地址, 扣图服务地址, AI消除图服务器地址, AI区域分割服务器地址)
    setServerAddress: (depthUrl: string, processImageUrl: string, inpaintUrl: string, aiUrl: string, folderUrl: string) => void,  

    showLayorPanel: Function,  // 显示图层面板
    closeLayorPanel: Function, // 隐藏图层面板

    captureThrumbailImage : Function,    // 获取画板的缩略图 - (width, height, callback)

    exportProjectData: Function,    // 读取项目数据,0 表示成功  -1表示无数据
    loadProjectData: Function,      // 初始化项目数据(json, callback),callback-其中code=0代表成功，小于0作为错误码返回，result返回错误信息，大于0代表当前处理状态

    undo: () => void,               // 撤销,上一步
    redo: () => void,               // 回退,下一步
    getUndoState: () => boolean,    // 画板数据是否可以撤销 - bool
    getRndoState: () => boolean,    // 画板数据是否可以回退 - bool

    switchMouseType: (type: any) => void, // 切换鼠标交互模式,(mode) 0:指针  1:手势
    merageMockUpImagesToSingleImage: (callback: (code: number, result: any) => void) => void, // 将选中的底图转换成向量图

    updateSelectImage: Function , // 替换选中的图像, (url|file, function) : url|file(地址) , function: code为0表示成功

    // ====== 画笔 =====
    startManAIErase: (callback: (code: number) => void) => void,    // 开启手动消除
    startCicleAIErase: () => void,                                  // 开启圈选消除
    startAIErase: (callback: (code: number) => void) => void,       // 请求AI消除并刷新渲染面板
    stopEraseDraw: () => void,                                      // 关闭并释放绘制面板

    switchAreaEraseType: (type: number) => void,                    // 切换涂抹与擦除(底图): (type): 0擦除  1涂抹
    startAreaErase: (callback: (code: number) => void) => void,     // 区域画笔(底图)
    // startClearErase: Function,                                   // 区域擦除画笔(底图)
    processAreaErase: (callback: (code: number) => void) => void,   // 区域画笔生效接口(底图)

    getDrawPenWidth: () => any,                                     // 获取画笔大小(0.1-50),px
    setDrawPenWidth: (width: any) => void,                          // 设置画笔大小

    // ======= 智能画笔 =======
    startAlAreaErase : (code: any) => {} ,                              // 开启智能底图涂抹(针对底图) - (function) code: 0成功
    switchAIAreaEraseType: (type: number) => void,                      // 智能底图画笔切换(针对底图) -  (type) type: 0代表擦除画笔，1代表区域画笔
    analysisAIAreaErase : (callback: (code: number) => void) => void,   // 区域分析(针对底图) - (function) code: 0成功
    processAIAreaErase: (callback: (code: number) => void) => void,     // 智能底图涂抹生效(针对底图)- (function) code: 0成功


    // ====== 图层 =======
    getFirstItemsData: () => any[],                                         // 图层列表-第一层, return list
    getSecondaryItemsData: (children: any) => any[],                        // 根据获取到的第一层目录去查找第二层数据，获取依附在背景图下的向量图数据, return list
    getSecondaryItemsDatabyUUid: (uuid: string) => any[],                   // 根据第一层uuid查找第二层数据,(uuid) return list
    getAllImageData: () => any[],                                           // 获取所有图像数据, return list

    positioningToImageByUuid: (uuid: string) => void,                       // 定位到指定图层(uuid)

    swapFirstItemDataByUuid: (selectUuid: string, toIndexUuid: string) => void, // 排序更改指定渲染层级到目标层级，同时刷新面板(selectUuid, toIndexUuid),源ID，目标ID

    modifyItemDataLockStateByUuid: (uuid: number, bBlock: boolean) => void,   // 更改指定图层的锁定状态(uuid, bBlock:boolean), 源ID，是否缩写
    getItemDataLockStateByUuid: (uuid: string) => boolean,                    // 获取指定图层的锁定状态(uuid), return boolean

    modifyItemDataHiddenStateByUuid: (uuid: number, bBlock: boolean) => void, // 更改指定图层的隐藏状态(uuid, bBlock:boolean), 源ID，是否缩写
    getItemDataHiddenStateByUuid: (uuid: string) => boolean,                  // 获取指定图层的隐藏状态(uuid), return boolean

    // ======= 智能换色 ======
    startAIColorErase: (callback: (code: number) => void) => void,          // 打开智能换色面板(向量图) - (function) code: 状态0代表初始化成功，1000代表点击区域事件，其他值代表当前状态
    processAIColorErase: (callback: (code: number) => void) => void,        // 处理智能换色结果并刷新画板(向量图) - (function) code: 0成功
    
    // 针对区域进行参数调整(针对向里图)(color - #XXXXXX,saturation - 饱和度, opacity - 不透明度,contrast - 对比度,brightness - 亮度) void
    modifySelectAreaImageParameter: (color: string, saturation: number, opacity: number, contrast: number, brightness: number) => void, 

    startManColorDooleErase: (callback: (code: number) => void) => void,    // 开启手动涂抹画板且开启换色功能(针对向量图) - (function) code: 0成功
    switchManColorDooleType: (type: number) => void ,                       // 手动涂抹画笔切换(针对向里图) - (type) type: 0代表擦除画笔，1代表区域画笔
    modifyManColorDoole: (color: string) => void,                           // 修改手动涂抹颜色值(针对向量图) - (color) 颜色值，十六进制
    processManColorDooleErase:  (callback: (code: number) => void) => void, // 生效手动涂抹效果并刷新画板(针对向量图)  - (function) code: 0成功
    
}

export enum ActionName {
    CHANGE_MOUSE_MODE, // 切换鼠标模式

    INIT_EDITOR_DATA, // 初始化编辑器数据
    LOADING_IMAGE, // 加载图片
    REPLACE_IMAGE, // 替换图片
    MOCKUP,  // 转Mockup   
    VECTOR, // 转向量图
    VECTOR_REMOVE_BACKGROUD, // 向量图去除背景
    VECTOR_ON_MOCKUP_SETTING, // 底图上的向量图属性设置
    DOWNLOAD,  // 下载(Mockup、Frame、图片)
    GET_EDITOR_DATA, // 获取编辑器数据

    
    VECTOR_AI_ERASE_MAN_START, // 开启手动消除
    VECTOR_AI_ERASE_CIRCLE_START, // 开启手动消除
    SETTING_PEN_SIZE,  // 设置画笔大小
    GET_PEN_SIZE,       // 获取画笔大小
    VECTOR_AI_ERASE_CLEAR, // AI消除
    VECTOR_AI_ERASE_CLOSE, // 关闭画笔


    MOCKUP_AREA_START,      // 开启区域
    MOCKUP_PEN_SWITCH ,  // 切换 涂抹|擦除
    MOCKUP_CONFIRM,     // 确认区域画笔

    MOCKUP_AI_AREA_START,    // 开启智能画笔区域
    MOCKUP_AI_PEN_SWITCH ,  // 切换智能画笔 涂抹|擦除
    MOCKUP_AI_ANALYSIS,     // 开始分析
    MOCKUP_AI_CONFIRM,     // 确认智能画笔区域画笔

    TOOLSKIT_UNDO,   // 撤销,上一步
    TOOLSKIT_REDO,   // 回退,下一步
    GET_LAYER_LIST,  // 获取图层列表
    LAYER_LOCKED_STATE, // 图层锁定/解锁
    LAYER_HIDE_STATE,  // 图层隐藏/显示
    LAYER_POSITION,     // 图层定位
    LAYER_SORT,         // 图层排序


    SMART_COLOR_START,              // 打开智能换色面板(向量图) 
    SMART_COLOR_PROCESS,            // 处理智能换色结果并刷新画板(向量图)
    SMART_COLOR_SETTING,            // 针对区域进行参数调整(针对向量图)
    SMART_COLOR_MAN_START,          // 开启手动涂抹画板且开启换色功
    SMART_COLOR_MAN_SWITCH_TYPE,    // 手动涂抹画笔切换(针对向里图)
    SMART_COLOR_MAN_UPDATE,         // 修改手动涂抹颜色值(针对向量图)
    SMART_COLOR_MAN_PROCESS,       // 生效手动涂抹效果并刷新画板
}


/**
 * 操作指令
 */
export type Action = {
    /**
     * 指令名称
     */
    name: ActionName,
    /**
     * 指令参数
     */
    params ?: string | FineTuningObject | LayerItem
}


export type MockupObject = {
    uuid: number,
    type: number,
    mImageWidth: number,
    mImageHeight: number,
    mImageUrl?: string,

    mapUUId: string, // 当前向量图指向的底图(置于底图上)
    
} & FineTuningObject ;

export type FineTuningObject = {
    mapUUId?: string, // 当前向量图指向的底图(置于底图上)
    opacity: number, // 透明度
    saturation: number, // 饱和度
    brightness: number, // 亮度
    contrast: number    // 对比度
}

export type SmartColorFineTuningObject = {
    color ?: string
} & FineTuningObject



export type EventCallbackParams = {
    type: EventCallbackParamsType,
    data?: MockupObject | ItemBase64 | string | any | null,
    list?: any[]
}

export type ItemBase64 = {
    md5: string,
    base64: Uint8Array,
    type: number,
    imageData : any
}

export enum EventCallbackParamsType {
    EDITOR_INIT_FINISHED = 100, // 编辑器初始化成功
    OBJECT_SELECT = 101,  // 对象选择
    OBJECT_CANCEL_SELECT = 102, // 取消选择
    ACTION_FINISH = 103,  // Action指令完成
    EDITOR_CHANGE = 104,  // 编辑器有数据变化
    GET_EDITOR_DATA_CALLBACK = 105,  // 获取编辑器数据后的回调
    DOWNLOAD_CALLBACK = 106, // 下载之后的回调
    GET_PEN_SIZE_CALLBACK = 107, // 获取画笔大小的回调

    PEN_CONFIRM_CALLBACK = 108, // AI消除成功后的回调(向量图) | 确认区域画笔成功后的回调(底图)

    ITEM_BASE64_CHANGE = 109,  // 当图像有base64变化时的回调，需要暂存
    MOCKUP_COVERTING = 110,     // 正在转mockup中
    VECTOR_REMOVE_BACKGROUD_CALLBACK = 111, // 去除背景之后的回调
    MOUSE_CHANGE_CALLBACK = 112, // 画板行为切换时的回调(选择/拖动)

    PANEL_SCALE_CALLBACK = 200,  // 画布缩放时的回调
    GET_LAYER_LIST_CALLBACK = 201, // 获取图层列表的回调
    LAYER_MODIFY_CALLBACK = 202,  // 画布变动时的回调

    SMART_COLOR_AI_READY = 300,  // 智能换色 分割服务已就绪
    SMART_COLOR_CHOICE_STATUS = 301,  // 智能换色的部位选中状态
}
