import { useEffect, useState } from "react";

export type TabItem = {
    title : string,
    type ?: number,
    callback ?: Function
}

export default function List(props: {tabs: TabItem[] | TabItem, children: any, cols ?: number}){
    const {tabs, children, cols = 6} = props ;
    const [type, setType] = useState<number>(0);

    const headTabs = tabs && Array.isArray(tabs) ? tabs : [{...tabs, type: 0}];

    const clickHandler = (item: TabItem) => {
        item.callback && item.callback(item.type)
        typeof(item.type) !== 'undefined' && setType(item.type)
    }

    return (
        <div className=" p-[30px] bg-[#F8F8F8] w-full h-full">
            <div className="flex gap-[30px]">
                {
                    headTabs && headTabs.map(item => (
                        <p className={`font-bold text-[14px] cursor-pointer ${item.type == type ? 'text-#[000000]' : 'text-[#BFBFBF]'}`}
                            key={item.type}
                            onClick={() => clickHandler(item)}>{item.title}</p>
                    ))
                } 
            </div>
            <div className="mt-[20px] grid gap-4 grid-cols-6 pb-[20px]"
                style={{
                    gridTemplateColumns: `repeat(${cols}, minmax(0, 1fr))`
                }}>
                {children}
            </div>
        </div>
    )
}