import Topbar from "./topbar";
import Menubar from "./menubar";
import Search from "../../context/search";
import Global from "components/common/global";

export default function Layout(props: {children: any, isHasMenuBar ?: boolean, isHasSearch ?: boolean}) {
  const {isHasSearch = true} = props ;
  return (
      <Global>
        <div className="min-h-screen flex flex-col ">
          {
            isHasSearch ? <Search><Content {...props} /></Search>
            : <Content {...props} />
          }
            
        </div>
      </Global>
  );
}

function Content(props: {children: any, isHasMenuBar ?: boolean}){
  const {children, isHasMenuBar = true} = props ;
  return (
    <>
      <Topbar />
      <div className="grow flex h-full">
        {
          isHasMenuBar && <Menubar />
        }
        <div className="grow bg-[#F8F8F8]">
          {children}
        </div>
      </div>
    </>
  )
}
