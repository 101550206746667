
import { createContext, useContext, useEffect, useState } from "react";
import { useLoadingContext } from "./loadingLayout";

interface GuideObj {
  isStartGuide: boolean,
  next: Function
}
export const GuideContext = createContext<GuideObj|null>(null);

export function useGuideContext() : GuideObj | null{
  return useContext(GuideContext);
}



/**
 * 新手指引布局
 */
export default function GuideLayout(props: {children: any}) {
    const {children} = props ;

    const [isStartGuide, setIsStartGuide] = useState<boolean>(false);
    const [step, setStep] = useState<number>(0);
    const [guideItemInfo, setGuideItemInfo] = useState<GuideItemInfo>();
    const loadingContext = useLoadingContext();
    const MAX_STEP = 4 ;

    const next = () => {
        setStep(step + 1)
    }

    const finish = () => {
        setIsStartGuide(false);
        localStorage.setItem('GUIDE', "1");
    }

    useEffect(() => {
        if(!localStorage.getItem('GUIDE')){
            setIsStartGuide(true)
            setStep(1);
        }
    },[])

    useEffect(() => {
        if(step == 0) return ;
        if(step > MAX_STEP){
            return finish();
        }
        let info : GuideItemInfo | undefined = undefined;
        const elementPosition = document.getElementById(`step${step}_element`)?.getBoundingClientRect();
        if(!elementPosition) return ;
        const {left, top, width, height} = elementPosition ;
        switch(step){
            case 1 :
                info = {
                    size : { left: left - 15, top: top-10, width: width + 25, height: height + 18},
                    content: {
                        contentDirection: 1,
                        title: 'Upload Image',
                        content: ['Click on the upload button to upload the product image you want to convert into a mockup and design.']
                    }
                }
                break;
            case 2 :
                info = {
                    size : { left: left-15, top: 0, width: width + 25, height: height + 25},
                    content: {
                        contentDirection: 2,
                        title: 'Function buttons',
                        content: ['Hover over a button to view information about the corresponding pixmap function button.','You can use them yourself as needed.']
                    }
                }
                break;
            case 3 :
                info = {
                    size : { left: left-10, top: top-10, width: width + 20, height: height + 20},
                    content: {
                        contentDirection: 2,
                        title: 'Convert to mockup',
                        content: ['Converts the selected picture layer into a model image. Upload a new pattern image, drag it onto that model image and it will stretch to fit its surface.']
                    }
                }
                break;
            case 4 :
                info = {
                    size : { left: left-10, top: top-10, width: width + 20, height: height + 20},
                    content: {
                        contentDirection: 2,
                        title: 'Download',
                        content: ['Select any image designed on the drawing board to download, you can set the size of the image as well as the format (JPG, PNG).']
                    }
                }
                break;
        }
        info && setGuideItemInfo(info);
    },[step])

    return (
        <>
          <GuideContext.Provider value={{
            isStartGuide, next
          }}>
            {children}
            {
                !loadingContext?.isLoadingLayout && isStartGuide && guideItemInfo &&
                <>
                    <div className="fixed left-0 top-0 right-0 bottom-0 w-full h-full bg-[#000000]/[0.5] z-[9999999]"
                        style={{
                            mixBlendMode : "hard-light",
                            pointerEvents: "auto"
                        }}>
                        <div className="absolute left-[50px] top-[50px] w-[100px] h-[50px] rounded-[10px] bg-[gray]"
                            style={{
                                ...guideItemInfo.size,
                                left : guideItemInfo.size.left === 0.5 ? '50%' : guideItemInfo.size.left
                            }}>
                        </div>
                    </div>
                    <div className="absolute left-[300px] top-[100px] w-[440px] p-[20px] rounded-[20px] bg-[#4986FF] text-[#FFFFFF] z-[99999999]"
                        style={{
                            top: guideItemInfo.content.contentDirection === 1 ? guideItemInfo?.size.top - 10 : (guideItemInfo?.size.top + 85),
                            left: guideItemInfo.content.contentDirection === 1 ? guideItemInfo?.size.left + guideItemInfo.size.width + 40 
                                : ( guideItemInfo?.size.left),
                            marginLeft: guideItemInfo.content.contentDirection === 1 ? 0 : (440 - guideItemInfo.size.width) / -2
                        }}>
                        <span className="text-[16px] font-bold">{guideItemInfo?.content.title}</span>
                        <>
                        {
                            guideItemInfo.content.content.map((c: string, i: number) => (
                                <p className="mt-[10px]" key={i}>{c}</p>
                            ))
                        }
                        </>
                        <div className="flex items-center mt-[20px]">
                            <span>{step}/{MAX_STEP}</span>
                            <span className="grow text-right cursor-pointer" 
                                onClick={finish}>Skip</span>
                            <span className="px-[20px] py-[5px] bg-[#9ABCFF] rounded-[20px] ml-[10px] cursor-pointer"
                                onClick={next}>{step == MAX_STEP ? 'Got it!' : 'Next'}</span>
                        </div>
                        <div className="absolute w-[30px] h-[30px] bg-[#4986FF] rotate-[45deg] "
                            style={{
                                left: guideItemInfo.content.contentDirection == 1 ? -10 : '50%',
                                marginLeft: guideItemInfo.content.contentDirection == 1 ? 0 : -15,
                                top: guideItemInfo.content.contentDirection == 1 ? 25 : -10,
                            }}>

                        </div>
                    </div>
                </>
            }
            
          </GuideContext.Provider>
        </>
    );
}

type GuideItemInfo = {
    size : Size,
    content: Content
}

type Size = {
    left : number,
    top : number,
    width: number,
    height : number,
    marginLeft?: number
}

type Content = {
    contentDirection: number,
    title : string,
    content: string[]
}