import Layout from "components/base/layout";
import { useLoadingContext } from "context/loadingLayout";
import { useEffect } from "react";

export default function CreditRule() {
  return (
    <Layout isHasMenuBar={false} isHasSearch={false}>
      <Content />
    </Layout>
  );
}

function Content() {
  const loadingContext = useLoadingContext();

  useEffect(() => {
    if(loadingContext?.isLoadingLayout) loadingContext?.setIsLoadingLayout(false);
  },[])

  return (
    <div className="flex justify-center">
      <div className="w-[1000px]">
        <h2 className="text-[24px] w-full text-center font-bold mt-[30px]">Subscription Credits Rules</h2>
        <p className="text-[16px] font-semibold mt-[20px]">Credits utilization form</p>
        <div className="w-[440px] px-[20px] py-[10px] bg-[#FFFFFF] mt-[20px] rounded-[10px]">
          <div className="w-full flex">
            <span className="grow pb-[3px] border-b leading-[25px] text-[14px]">Operation</span>
            <span className="w-[100px] pb-[3px] border-l border-b text-center leading-[25px] text-[14px]">Credits</span>
          </div>
          <div className="w-full flex">
            <span className="grow pt-[3px] leading-[25px] text-[14px]">Convert image to mockup</span>
            <span className="w-[100px] pt-[3px] border-l text-center leading-[25px] text-[14px]">2</span>
          </div>
        </div>
        <p className="text-[16px] font-semibold mt-[20px]">About the automatic refresh of credits</p>
        <p className="mt-[10px] text-[14px]">Free package 50 credits per month, fixed refresh every month from the date of your first use, credits do not add up.</p>
        <p className="mt-[10px] text-[14px]">The basic plan is 500 credits per month, and the credits will be updated to 500 from the date of your subscription, and then refreshed once every month, and the credits will not add up.</p>

        <p className="text-[16px] font-semibold mt-[20px]">Subscription cancellation policy</p>
        <p className="mt-[10px] text-[14px]">You can unsubscribe at any time. Click the "Upgrade" button and then switch back to the free package. After you cancel your subscription, the remaining credits can still be used until the end of the subscription period, and the credits will be automatically refreshed after the end of the subscription period.</p>

        <p className="text-[16px] font-semibold mt-[20px]">How to get more credits when you run out of subscription credits.</p>
        <p className="mt-[10px] text-[14px]">You can get more credits for free by: Make your valuable suggestions. <a href="/credit/free" className="underline">Get free credits</a>. Credits earned for free through the above methods do not expire.</p>

        <p className="text-[16px] font-semibold mt-[20px]">Refund request</p>
        <p className="mt-[10px] text-[14px]">If you use less than 20 credits during the subscription period, you will be eligible for a full refund, and if you use more than 20 credits, no refund will be provided. To initiate a refund, please cancel your subscription and uninstall and contact us at contact@mockuplabs.com to make a refund.</p>
      </div>
    </div>
  );
}