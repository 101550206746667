import DialogConfirm from "components/common/dialogConfirm";
import { createContext, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import Loading from "../components/common/loading";
import { useHttpUtil } from "./httpContext";
import { usePermissionContext } from "./permission";


interface LoadingContextObj {
  isLoadingLayout: boolean,
  setIsLoadingLayout: Function
}
export const LoadingContext = createContext<LoadingContextObj|null>(null);

export function useLoadingContext() : LoadingContextObj | null{
  return useContext(LoadingContext);
}

/**
 * Loading布局
 */
export default function LoadingLayout(props: {isDefaultLoading?: boolean , children: any}) {
    const {isDefaultLoading = true, children} = props ;

    const [isLoadingLayout, setIsLoadingLayout] = useState<boolean>(isDefaultLoading);
    const [isLoadingCharge, setIsLoadingCharge] = useState<boolean>(false);
    const [isChargeSuc, setIsChargeSuc] = useState<boolean>(false);

    const httpUtil = useHttpUtil();
    const updatorContext = usePermissionContext();
    const routeLocation = useLocation();
    const navigate = useNavigate();
    const params = new URLSearchParams(routeLocation.search);

    useEffect(() => {
        const charge_id = params.get("charge_id")
        if(charge_id){
          setIsLoadingCharge(true)

          let max = 6, curr = 0 ;
          let timer : NodeJS.Timeout ;
          timer = setInterval(async () => {
              const res = await httpUtil.post('/biz/plan/order/status',{
                  shopifySubscriptionId : charge_id
              });
              if(!res.errCode || curr ++ >= max){
                clearInterval(timer);
                setIsLoadingCharge(false);
                // setIsChargeSuc(true);
              }
              if(!res.errCode){
                setIsChargeSuc(true);
              }
          }, 500)
        }
    }, [])

    const confirm = () => {
      updatorContext?.updator();
      setIsChargeSuc(false);
      navigate(routeLocation.pathname)
    }

    return (
        <>
          <LoadingContext.Provider value={{
            isLoadingLayout, setIsLoadingLayout
          }}>
            {
              (isLoadingLayout || isLoadingCharge) &&
                <div className="absolute w-full h-full left-0 top-0 z-[999999] bg-[#FFFFFF]">
                  <div className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]">
                    <Loading />
                  </div>
                </div>
            }
            {
              isChargeSuc &&
                <DialogConfirm
                  width={600}
                  title="You have successfully enabled auto-renewal !"
                  content={[
                      'Subscription details can be viewed by clicking on your account avatar and then clicking on “My Account”.'
                  ]}
                  confirm={confirm}></DialogConfirm>
            }
            {children}
          </LoadingContext.Provider>
        </>
    );

}